function MyMenuTitleBox(data){
    return (
        <div className={`myMenu_titleBox ${data.addClass||""}`}>
            {data.title ? <h1 className="myMenu_title" dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
            {data.date ? <p className="myMenu_date" dangerouslySetInnerHTML={{__html:data.date}}/> : ""}
        </div>
    );
}

function MyMenuContentsBox(data){
    return (
        <div className={`myMenu_contentsBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function MyMenuContents(data){
    return (
        <div className={`myMenu_contetns editerBox ql-snow ${data.addClass ? data.addClass : ""}`}>
            <div className="myMenu_contetnsItem ql-editor" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

export {MyMenuTitleBox,MyMenuContentsBox,MyMenuContents};