import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, PageTitle } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { CustomPopup, Popup } from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { setDateTime, setDateWeek } from "js/function";
import { PopupInputBox } from "component/basic";
import { CustomSelect, DatePicer, InputBoxCol2, InputBoxOnly, InputItemBox, InputNameOnly, InputTap } from "component/basic/formItems";
import { MypageArea, MypageAreaTitleBox, MypageBtnBox, MypageContents, MypageFullSection } from "component/mypage/items";
import { MyOrderDelivery, MyOrderPaymentInfo, MypageOrderTable } from "component/mypage/order";
import { OrderFixedItemBox, OrderFixedItemTextBox, OrderFixedSubItemBox, OrderInputBox } from "component/product/order";
import { useDaumPostcodePopup } from "react-daum-postcode";

const MyOrderDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const type = param.type;

	const open = useDaumPostcodePopup();

    const [submitChk,setSubmitChk] =  useState(true);

    const [pageBtnType,setPageBtnType] =  useState(null);

    const [orderData,setOrderData] =  useState({
        name:null,
        phone:null,
        addr:null,
        detailAddr:null
    });

    const [receiverData,setReceiverData] =  useState({
        name:null,
        phone:null,
        addr:null,
        detailAddr:null,
        deliveryRequest:null
    });

    const [popupData,setPopupData] =  useState(null);
    const [customPopupDataPeriod,setCustomPopupDataPeriod] =  useState(null);
    const [customPopupDataRefund,setCustomPopupDataRefund] =  useState(null);

	const [periodBtnChk,setPeriodBtnChk] =  useState(true);
	const [refundBtnChk,setRefundBtnChk] =  useState(true);

	const [subscribeDate,setSubscribeDate] =  useState({
        begin_date:null,
        end_date:null
    });
    const [dateChk,setDateChk] = useState(false);
    const [excludeDates,setExcludeDates] = useState([]);
    const [maxDate,setMaxDate] = useState(null);

	const [periodData,setPeriodData] =  useState({
		id:null,
		periods:null,
		week_count:null,
		day_count:null
	});

	const [refundData,setRefundData] =  useState({
		id:null,
		type:null,
		reason:null,
		name:null,
		phone:null,
		address:null,
		address_detail:null,
		delivery_request:null
	});
	const [refundAddrType,setRefundAddrType] =  useState(null);

    const orderApi = useGet({
        url:`/order/detail?order_id=${id}`,
        loginType:"login"
    });
    const orderApiData = orderApi?.data;
    const orderDetailData = orderApiData?.order;
    const orderProductData = orderApiData?.order_products;

	const deliveryApi = useGet({
        url:`/delivery/api`,
        loginType:"login"
    });
    const deliveryApiKey = deliveryApi?.data?.delivery_api_key;

    const [orderDeliverySubmit,setOrderDeliverySubmit] =  useState(0);
    const [orderDeliveryData,setOrderDeliveryData] =  useState({
        logistics_company:null,
        tracking_number:null
    });

	const profileApi = useGet({
        url:`/profile`,
        loginType:"login"
    });
    const profileApiAddrDataUser = profileApi?.data?.user;
    const profileApiAddrData = profileApi?.data?.user_address;

    function orderCancelPopup(id){
        setPopupData({
			addClass:null,
			title:"주문 취소",
			text:`구매하신 주문을 ${id === "all" ? "모두 " : ""}취소하시겠습니까?<br/>취소하신 주문은 3~7 영업일 이내 환불됩니다.`,
			closeType:true,
			closeFunc:(e) => {setPopupData(null)},
			btnFunc0:(e)=>{setPopupData(null)},
			btn0:"닫기",
			btn0Type:"line",
			btn1:"주문 취소",
			btn1Type:"",
			btnFunc1:()=>{setPopupData(null);orderCancelFunc(id)}
      	});
    }

    function orderCancelFunc(id){
        if(submitChk){
			setSubmitChk(false);

			let formData = new FormData();

            if(id === "all"){
                orderProductData?.forEach(function(item,i){
                    formData.append("order_id[]", item.id);
                });
            }else{
                formData.append("order_id[]", id);
            }

			fatchSet.FatchApi({
				type:"POST",
				formDataItem: formData,
				loginType: "login",
				url: `/order/cancel`,
				success: (data) => {
					setSubmitChk(true);
                    if(id === "all"){
                        setPopupData({
                            addClass:null,
                            title:"주문 취소 완료",
                            text:`구매하신 주문이 ${id === "all" ? "모두 " : ""}취소 완료되었습니다.<br/>취소하신 주문은 3~7 영업일 이내 환불됩니다.`,
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{
                                navigate(-1);
                            },
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                
                        setTimeout(() => {
                            navigate(-1)
                        }, 10000);
                    }else{
                        window.location.reload(true);
                    }
				},
				err: (data) => {
					setSubmitChk(true);
					if(data.data || data.alert){
						window.errPopupOpen(data);
					}
				}
			})
      	}
    }

	function orderDeliveryPopup(logistics_company,tracking_number){
        let setData ={
            logistics_company:null,
            tracking_number:null
        }
        if(logistics_company === "all"){
            if(orderProductData?.length > 0){
                setData = {
                    logistics_company:orderProductData[0]?.logistics_company,
                    tracking_number:orderProductData[0]?.tracking_number
                }
            }
        }else{
            setData = {
                logistics_company:logistics_company,
                tracking_number:tracking_number
            }
        }
        setOrderDeliveryData(setData);

        setTimeout(() => {
            setOrderDeliverySubmit(true);
        }, 100);
    }

	function orderPeriodPopup(id,periods,week_count,day_count){
		let setData = {
			id:id,
			periods:periods,
			week_count:week_count,
			day_count:day_count
		}
		setPeriodData(setData);
		setTimeout(() => {
			setCustomPopupDataPeriod({
				addClass:"periodCustomPopup",
				title:"기간변경/일시정지",
				closeType:true,
				closeFunc:(e) => {
					setTimeout(() => {
						setCustomPopupDataPeriod(null);
						dateReSet();
					}, 200);
				}
			});
		}, 100);
    }

    function orderPeriodFunc(){
		if(submitChk){
			setSubmitChk(false);

			let formData = new FormData();

			formData.append("order_product_id",periodData?.id);
			formData.append("date", subscribeDate?.begin_date);

			fatchSet.FatchApi({
				type:"POST",
				formDataItem: formData,
				loginType: "login",
				url: `/order/subscribe/period`,
				success: (data) => {
					setSubmitChk(true);
					setCustomPopupDataPeriod(null);
					dateReSet();
					
					window.location.reload(true);
				},
				err: (data) => {
					setSubmitChk(true);
					if(data.data || data.alert){
						window.errPopupOpen(data);
					}
				}
			})
      	}
    }

	function dateReSet(){
		let oldData = {
			begin_date:null,
			end_date:null
		};
		let setData = {
			id:null,
			periods:null,
			week_count:null,
			day_count:null
		}

        setSubscribeDate(oldData);
		setPeriodData(setData);

        setTimeout(() => {
            setDateChk(!dateChk);
        }, 100);
	}

	function dateSet(beginDate){
        let oldData = {...subscribeDate};
        if(beginDate){
            let endDate = new Date(beginDate?.replace(/-\./g,"/"));
            endDate.setDate(endDate.getDate() + 28);
 
            oldData.begin_date = beginDate;
            oldData.end_date = setDateTime(endDate,"yymmdd","hymd");
        }else{
            oldData.begin_date = null;
            oldData.end_date = null;
        }

        setSubscribeDate(oldData);
        setTimeout(() => {
            setDateChk(!dateChk);
        }, 100);
    }

    function excludeDatesSetting(monthData){
        let setData = [];
        if(monthData){
            const nowML = new Date(monthData?.split("-")[0],monthData?.split("-")[1],0);
            const nowML_date = nowML?.getDate();
            const nowDateArr = [...Array(nowML_date + 1)?.keys()]?.slice(1);

            nowDateArr.forEach((item,i)=>{
                let chkDate = `${monthData}-${item}`;
                if(!(setDateWeek(chkDate) === "수" || setDateWeek(chkDate) === "토")){
                    setData.push(new Date(chkDate.replace(/\-/g,"/")));
                }

                if(nowDateArr?.length - 1 === i){
                    setMaxDate(new Date(chkDate.replace(/\-/g,"/")));
                    setTimeout(() => {
                        setExcludeDates(setData);
                        setTimeout(() => {
                            setDateChk(!dateChk);
                        }, 100);
                    }, 100);
                }
            });

            if(!nowDateArr || nowDateArr?.length <= 0){
                setExcludeDates(setData);
                setMaxDate(null);
                setTimeout(() => {
                    setDateChk(!dateChk);
                }, 100);
            }
        }else{
            setExcludeDates(setData);
            setMaxDate(null);
            setTimeout(() => {
                setDateChk(!dateChk);
            }, 100);
        }
    }

	function excludeDateReset(){
		let nowDate = new Date();

        excludeDatesSetting(setDateTime(nowDate,"yymm","hymd"));
        // dateSet(setDateTime(nowDate,"yymmdd","hymd"));
	}

	function orderRefundReset(){
		let resetData = {
			id:null,
			type:null,
			reason:null,
			name:null,
			phone:null,
			address:null,
			address_detail:null,
			delivery_request:null
		}
		setRefundData(resetData);
    }

	function orderRefund_dataSet(key,val){
		let resetData = {...refundData}
		resetData[key] = val;
		setRefundData(resetData);
    }

	function orderRefundPopup(id){
		let setData = {...refundData,id:id}
		setRefundData(setData);
		setTimeout(() => {
			setCustomPopupDataRefund({
				addClass:"refundCustomPopup",
				title:"교환/환불 접수",
				closeType:true,
                submitType:id,
				closeFunc:(e) => {
					setTimeout(() => {
						setCustomPopupDataRefund(null);
						orderRefundReset();
					}, 200);
				}
			});
		}, 100);
    }
	
	const handleComplete = (addrItem) => {
		orderRefund_dataSet("address",addrItem.address)
    };

	const addrShOpen = () => {
        open({ onComplete: handleComplete });
    };

    function orderRefundFunc(type){
		if(submitChk){
			setSubmitChk(false);

			let formData = new FormData();

            if(customPopupDataRefund?.submitType === "all"){
                orderProductData?.forEach(function(item,i){
                    formData.append("order_id[]", item.id);
                })
            }else{
                formData.append("order_id[]", refundData?.id);
            }
            
			formData.append("type",refundData?.type);
			formData.append("reason",refundData?.reason);
			formData.append("name",refundData?.name);
			formData.append("phone",refundData?.phone?.replace(/-/g,""));
			formData.append("address",refundData?.address);
			formData.append("address_detail",refundData?.address_detail);
			formData.append("delivery_request",refundData?.delivery_request);

			fatchSet.FatchApi({
				type:"POST",
				formDataItem: formData,
				loginType: "login",
				url: `/order/refund`,
				success: (data) => {
					setSubmitChk(true);
					setCustomPopupDataRefund(null);
					orderRefundReset();
					
					window.location.reload(true);
				},
				err: (data) => {
					setSubmitChk(true);
					if(data.data || data.alert){
						window.errPopupOpen(data);
					}
				}
			})
      	}
    }

	useEffect(() => {
        excludeDateReset();
    }, []);

    useEffect(() => {
        if(periodData?.id && subscribeDate?.begin_date){
			setPeriodBtnChk(false)
		}else{
			setPeriodBtnChk(true)
		}
    }, [subscribeDate,periodData]);

	useEffect(() => {
		let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(refundData?.id 
			&& refundData?.type 
			&& refundData?.reason 
			&& refundData?.name 
			&& phoneChk.test(refundData?.phone?.replace(/\-/g,""))
			&& refundData?.address 
			&& refundData?.address_detail 
		){
			setRefundBtnChk(false)
		}else{
			setRefundBtnChk(true)
		}
    }, [refundData]);

	useEffect(() => {
		if(refundAddrType === "기존 배송지"){
			let resetData = {...refundData,
				name:profileApiAddrData ? profileApiAddrData?.name : profileApiAddrDataUser?.name,
				phone:profileApiAddrData ? profileApiAddrData?.phone : profileApiAddrDataUser?.phone,
				address:profileApiAddrData ? profileApiAddrData?.address : null,
				address_detail:profileApiAddrData ? profileApiAddrData?.address_detail : null,
				delivery_request:profileApiAddrData ? profileApiAddrData?.delivery_request : null
			}
			setRefundData(resetData);
		}else{
			let resetData = {...refundData,
				name:null,
				phone:null,
				address:null,
				address_detail:null,
				delivery_request:null
			}
			setRefundData(resetData);
		}
    }, [refundAddrType]);

    useEffect(() => {
        if(orderApiData){
            let orderDataSetting = {
                name:orderDetailData?.order_name,
                phone:orderDetailData?.order_phone,
                addr:orderDetailData?.order_address,
                detailAddr:orderDetailData?.order_address_detail
            },
            receiverDataSetting = {
                name:orderDetailData?.address_name,
                phone:orderDetailData?.address_phone,
                addr:orderDetailData?.address_address,
                detailAddr:orderDetailData?.address_address_detail,
                deliveryRequest:orderDetailData?.address_delivery_request
            }
			
            setOrderData(orderDataSetting);
            setReceiverData(receiverDataSetting);

            if(type === "order"){
                if(orderProductData?.filter((el) => el.status == 0 || el.status == 1)?.length === orderProductData?.length){
                    setPageBtnType("orderCancel");
                }else if(orderProductData?.filter((el) => el.status == 3)?.length === orderProductData?.length){
                    setPageBtnType("orderDelivery");
                }else if(orderProductData?.filter((el) => el.status == 4)?.length === orderProductData?.length){
                    setPageBtnType("orderRefund");
                }else{
                    setPageBtnType(null);
                }
            }else if(type === "subscribe"){
                if(orderProductData?.filter((el) => el.status == 0)?.length === orderProductData?.length){
                    setPageBtnType("orderCancel");
                }else if(orderProductData?.filter((el) => el.status == 2 || el.status == 9)?.length === orderProductData?.length){
                    setPageBtnType("orderDelivery");
                }else if(orderProductData?.filter((el) => el.status == 3 || el.status == 10 || el.status == 15)?.length === orderProductData?.length){
                    // setPageBtnType("orderPeriod");
                    setPageBtnType(null);
                }else{
                    setPageBtnType(null);
                }
            }else{
                setPageBtnType(null);
            }
		}
    }, [orderApi]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                mBtn={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={"주문 상세정보"}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MypageContents>
                    <MypageFullSection>
                        <PageTitle
                            addClass="pcContents"
                            pageBack={()=>{navigate(-1)}}
                            contents={"주문 상세정보"}
                        />
                        <MypageArea>
                            <MypageAreaTitleBox addClass="notBorder" title={null}/>
                            <MypageOrderTable
                                type={type}
                                notItemText={type == "order" ? "주문 내역이 없습니다." : "구독 내역이 없습니다."}
                                orderCancel={(id)=>{orderCancelPopup(id)}}
                                orderDelivery={(logistics_company,tracking_number)=>{orderDeliveryPopup(logistics_company,tracking_number)}}
                                orderPeriod={(id,periods,week_count,day_count)=>{orderPeriodPopup(id,periods,week_count,day_count)}}
                                orderRefund={(id)=>{orderRefundPopup(id)}}
                                items={orderProductData}

                                pageType="detail"
                            />
                        </MypageArea>
                        <MypageArea>
                            <MypageAreaTitleBox addClass="" title="주문자 정보"/>
                            <OrderInputBox>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="성함"
                                        placeholder="-"
                                        value={orderData?.name && orderData?.name !== "null" ? orderData?.name : "-"}
                                        max={null}
                                        regexp={null}
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="연락처"
                                        placeholder="-"
                                        value={orderData?.phone && orderData?.phone !== "null" ? orderData?.phone : "-"}
                                        max={13}
                                        phoneSetting={true}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="주소"
                                        placeholder="-"
                                        value={orderData?.addr && orderData?.addr !== "null" ? orderData?.addr : "-"}
                                        max={null}
                                        regexp={null}
                                        readOnly={true}
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="상세 주소"
                                        placeholder="-"
                                        value={orderData?.detailAddr && orderData?.detailAddr !== "null" ? orderData?.detailAddr : "-"}
                                        max={null}
                                        regexp={null}
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                </InputBoxCol2>
                            </OrderInputBox>
                        </MypageArea>
                        <MypageArea>
                            <MypageAreaTitleBox addClass="" title="받아보실 분 정보"/>
                            <OrderInputBox>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="수령인 성함"
                                        placeholder="-"
                                        value={receiverData?.name && receiverData?.name !== "null" ? receiverData?.name : "-"}
                                        max={null}
                                        regexp={null}
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="연락처"
                                        placeholder="-"
                                        value={receiverData?.phone && receiverData?.phone !== "null" ? receiverData?.phone : "-"}
                                        max={13}
                                        phoneSetting={true}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="주소"
                                        placeholder="-"
                                        value={receiverData?.addr && receiverData?.addr !== "null" ? receiverData?.addr : "-"}
                                        max={null}
                                        regexp={null}
                                        readOnly={true}
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType"
                                        inputName="상세 주소"
                                        placeholder="-"
                                        value={receiverData?.detailAddr && receiverData?.detailAddr !== "null" ? receiverData?.detailAddr : "-"}
                                        max={null}
                                        regexp={null}
                                        disabled={true}
                                        func={(value)=>{}}
                                    />
                                </InputBoxCol2>
                                <InputItemBox
                                    inputType={null}
                                    addClass="textType"
                                    inputName="배송 요청사항 <span>(선택)</span>"
                                    placeholder="-"
                                    value={receiverData?.deliveryRequest && receiverData?.deliveryRequest !== "null" ? receiverData?.deliveryRequest : "-"}
                                    max={null}
                                    regexp={null}
                                    disabled={true}
                                    func={(value)=>{}}
                                />
                            </OrderInputBox>
                        </MypageArea>
                        <MypageArea>
                            <MypageAreaTitleBox addClass="notBorder" title="최종 결제 정보"/>
                            <MyOrderPaymentInfo
                                total_price={orderDetailData?.total_price}
                                delivery_fee={orderDetailData?.delivery_fee}
                                payment_price={orderDetailData?.payment_price}
                                payment_method={orderDetailData?.payment_method}
                                payment_date={orderDetailData?.payment_date}
                            />
                        </MypageArea>
                        <MypageBtnBox>
                            <BtnBox
                                boxType="fixed"
                                addClass={`pcBasicBtnBox col2 mRow2`}
                            >
                                {pageBtnType ? 
                                <BtnItem
                                    addClass=""
                                    contents={
                                        pageBtnType === "orderCancel" ? "주문 취소 접수"
                                        : pageBtnType === "orderDelivery" ? "배송현황 조회"
                                        : pageBtnType === "orderRefund" ? "교환/환불 접수"
                                        : ""
                                    }
                                    disabled={false}
                                    func={() => {
                                        if(pageBtnType === "orderCancel"){
                                            orderCancelPopup("all");
                                        }else if(pageBtnType === "orderDelivery"){
                                            orderDeliveryPopup("all");
                                        }else if(pageBtnType === "orderRefund"){
                                            orderRefundPopup("all");
                                        }
                                    }}
                                />
                                :""}
                                <BtnItem
                                    addClass="line"
                                    contents={"뒤로"}
                                    disabled={false}
                                    func={() => {navigate(-1)}}
                                />
                            </BtnBox>
                        </MypageBtnBox>
                    </MypageFullSection>
                </MypageContents>
            </ContentsSection>
            <Footer pcOnly={true}/>
			<CustomPopup data={customPopupDataPeriod}>
                <PopupInputBox>
					<OrderFixedItemBox
                        name={null}
                    >
						<InputNameOnly name="발송 재개일 선택"/>
                        <DatePicer
                            inputName=""
                            placeholder=""
                            value={subscribeDate?.begin_date}
                            dataChk={dateChk}
                            minCount={false}
                            maxDate={maxDate}
                            notTodaySel={true}
                            inline={true}
                            excludeDates={excludeDates}
                            func={(date) => {
                                dateSet(setDateTime(date,"yymmdd","hymd"));
                            }}
                            prevMonth={(date) => {excludeDatesSetting(date)}}
                            nextMonth={(date) => {excludeDatesSetting(date)}}
                        />
                        <OrderFixedItemTextBox
                            textColorClass=""
                            name={"구독 관리기간"}
                            val={`총 ${periodData?.periods} 주`}
                        />
                        <OrderFixedItemTextBox
                            textColorClass=""
                            name={"1주 당 식단 수"}
                            val={`${periodData?.week_count}개`}
                        />
                        <OrderFixedItemTextBox
                            textColorClass=""
                            name={"1일 당 끼니 수"}
                            val={`${periodData?.day_count}끼`}
                        />
                    </OrderFixedItemBox>
					<OrderFixedSubItemBox>
                        <OrderFixedItemTextBox
                            textColorClass=""
                            name={"구독 발송 재개일"}
                            val={subscribeDate?.begin_date ? `${subscribeDate?.begin_date}(${setDateWeek(subscribeDate?.begin_date)})` : "-"}
                        />
                        <OrderFixedItemTextBox
                            textColorClass=""
                            name={"구독 종료일"}
                            val={subscribeDate?.end_date ? `${subscribeDate?.end_date}(${setDateWeek(subscribeDate?.end_date)})` : "-"}
                        />
                    </OrderFixedSubItemBox>
                </PopupInputBox>
                <BtnBox
                    boxType="fixed"
                    addClass="row2"
                >
                    <BtnItem
                        addClass=""
                        contents="기간변경/일시정지"
                        disabled={periodBtnChk}
                        func={() => {
							orderPeriodFunc();
                        }}
                    />
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        disabled={false}
                        func={() => {
                            setCustomPopupDataPeriod(null)
							dateReSet();
                        }}
                    />
                </BtnBox>
            </CustomPopup>
			<CustomPopup data={customPopupDataRefund}>
                <PopupInputBox>
					<InputBoxOnly addClass="" inputName="접수 유형">
                	    <InputTap
                	        data={[
                	            {
                	                addClass:"",
                	                id:"exchange",
                	                name:"교환 접수"
                	            },
                	            {
                	                addClass:"",
                	                id:"refund",
                	                name:"환불 접수",
                	            }
                	        ]}
                	        val={refundData?.type}
                	        func={(value) => {orderRefund_dataSet("type",value)}}
                	    />
                	</InputBoxOnly>
					<CustomSelect
                        addClass=""
                        inputName="교환/환불 사유"
                        placeholder="사유를 선택해주세요."
                        value={refundData?.reason}
                        func={(name,val) => {orderRefund_dataSet("reason",val)}}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={[
                            {id:"상품에 하자가 있습니다.",name:"상품에 하자가 있습니다."},
                            {id:"상품에 하자가 있습니다.2",name:"상품에 하자가 있습니다.2"},
                            {id:"상품에 하자가 있습니다.3",name:"상품에 하자가 있습니다.3"}
                        ]}
                    />
					<InputBoxOnly addClass="" inputName="상품 회수지">
                	    <InputTap
                	        data={[
                	            {
                	                addClass:"",
                	                id:"기존 배송지",
                	                name:"기존 배송지"
                	            },
                	            {
                	                addClass:"",
                	                id:"신규 배송지",
                	                name:"신규 배송지",
                	            }
                	        ]}
                	        val={refundAddrType}
                	        func={(value) => {setRefundAddrType(value)}}
                	    />
                	</InputBoxOnly>
					<MypageAreaTitleBox addClass="minTitle" title="상품 회수지"/>
					<InputBoxCol2>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="성함"
                            placeholder="성함 입력"
                            value={refundData?.name}
                            max={null}
                            regexp={null}
                            func={(value)=>{orderRefund_dataSet("name",value)}}
                        />
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="연락처"
                            placeholder="연락처 입력"
                            value={refundData?.phone}
                            max={13}
                            phoneSetting={true}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            func={(value)=>{orderRefund_dataSet("phone",value)}}
                        />
                    </InputBoxCol2>
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="주소"
                        placeholder="주소 검색"
                        value={refundData?.address}
                        max={null}
                        regexp={null}
                        readOnly={true}
                        func={(value)=>{}}
                        clickInput={(value)=>{addrShOpen()}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="상세 주소"
                        placeholder="상세 주소 입력"
                        value={refundData?.address_detail}
                        max={null}
                        regexp={null}
                        func={(value)=>{orderRefund_dataSet("detailAddr",value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="배송 요청사항 <span>(선택)</span>"
                        placeholder="공동현관 비밀번호 등 배송 시 참고사항 작성"
                        value={refundData?.delivery_request}
                        max={null}
                        regexp={null}
                        func={(value)=>{orderRefund_dataSet("delivery_request",value)}}
                    />
                </PopupInputBox>
                <BtnBox
                    boxType="fixed"
                    addClass="row2"
                >
                    <BtnItem
                        addClass=""
                        contents="교환/환불 접수하기"
                        disabled={refundBtnChk}
                        func={() => {
							orderRefundFunc();
                        }}
                    />
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        disabled={false}
                        func={() => {
                            setCustomPopupDataRefund(null);
							orderRefundReset();
                        }}
                    />
                </BtnBox>
            </CustomPopup>
            <MyOrderDelivery
                t_key={deliveryApiKey}
                logistics_company={orderDeliveryData?.logistics_company}
                tracking_number={orderDeliveryData?.tracking_number}
                submit={orderDeliverySubmit}
                submitFunc={()=>{setOrderDeliverySubmit(false)}}
            />
			<Popup
                data={popupData}
            />
        </>
    );
};

export default MyOrderDetail;