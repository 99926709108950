import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { ContentsSection, InputContents } from "component/app/items";
import useGet from "api/useGet";
import { Footer } from "component/elements/footer";

const EditPhone = (props) => {
    const navigate = useNavigate();

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [popupData,setPopupData] =  useState(null);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [responseSeq,setResponseSeq] =  useState("");
    
    const [name,setName] =  useState("");
    const [birthday,setBirthday] =  useState("");
    const [birthdayBack,setBirthdayBack] =  useState("");
    const [phone,setPhone] =  useState("");
    const [phoneType,setPhoneType] =  useState("");

    const [submitChk,setSubmitChk] =  useState(true);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("name", name);
            formData.append("birth", `${birthday}${birthdayBack}`);
            formData.append("phone", phone?.replace(/\-/g,""));
            formData.append("mobile_carrier", phoneType.val);

            formData.append("is_modify", 1);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/certification`,
                success: (data) => {
                    setSubmitChk(true);

                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);
                    setLoadingBoxOpen(false);

                    setBtnChk(true);

                    setResponseSeq(data?.data?.response_seq);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function editPhoneFunc(){
        if(submitChk){
            if(cetifiedChk){
                setSubmitChk(false);
                setLoadingBoxOpen(true);

                let formData = new FormData();
                formData.append("name", name);
                formData.append("birth", `${birthday}${birthdayBack}`);
                formData.append("phone", phone);
                formData.append("mobile_carrier", phoneType.val);

                formData.append("response_seq", responseSeq);
                formData.append("certification_code", cetified);
                formData.append("is_modify", 1);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    loginType:"login",
                    url: `/certification/check`,
                    success: (data) => {
                        setSubmitChk(true);

                        setCetifiedComChk(true);

                        setTimerCount("3:00");
                        setCertifyErr("");

                        clearInterval(sval.current);
                        sval.current = null;

                        setLoadingBoxOpen(false);
                        
                        setPopupData({
                            addClass:null,
                            title:"휴대폰 번호 변경 완료",
                            text:"휴대폰 번호 변경이 완료되었습니다.",
                            closeType:false,
                            closeFunc:(e) => {setPopupData(null);navigate(-1)},
                            btnFunc0:(e)=>{setPopupData(null);navigate(-1)},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:null,
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
    
                        setTimeout(() => {
                            navigate(-1)
                        }, 5000);
                    },
                    err: (data) => {
                        setSubmitChk(true);
                        if(data.alert){
                            setCertifyErr(data.alert);
                        }
                        setLoadingBoxOpen(false);
                    }
                })
            }else{
                cetifiedStert();setCetifiedComChk(false);setCetified("")
            }
        }
    }

    useEffect(() => {
        let ragChk = /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]/g;
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(name.length > 0 
        && ragChk.test(`${birthday}${birthdayBack}`)
        && phoneChk.test(phone?.replace(/\-/g,""))
        && phoneType.val
        && (cetifiedChk ? cetified.length == 6 : true)){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [name,birthday,birthdayBack,phone,phoneType,cetified,cetifiedChk]);

    

    useEffect(() => {
        setLoadingBoxOpen(true);
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={false}
                mBtn_r2={true}
                pcFooter={true}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={"휴대폰 번호 변경"}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <InputContents
                    title={"휴대폰 번호 변경"}
                    subTitle={"휴대폰 변경을 위해 본인인증을 진행해주세요."}
                >
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="휴대폰 번호"
                        placeholder="‘-’ 없이 입력"
                        value={phone}
                        max={13}
                        phoneSetting={true}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setPhone(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("")}}
                    />
                    <CustomSelect
                        addClass="notName"
                        inputName="통신사"
                        placeholder="선택"
                        value={phoneType?.val}
                        func={(name,val) => {
                            let dataSet = {
                                val:val,
                                text:name
                            }
                            setPhoneType(dataSet);
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={[
                            {id:1,name:"SKT"},
                            {id:2,name:"KT"},
                            {id:3,name:"LGU+"},
                            {id:5,name:"알뜰폰(SKT)"},
                            {id:6,name:"알뜰폰(KT)"},
                            {id:7,name:"알뜰폰(LGU+)"}
                        ]}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="이름"
                        placeholder="이름"
                        value={name}
                        max={null}
                        regexp={null}
                        func={(value)=>{setName(value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="주민등록번호 앞 7자리"
                        placeholder="주민등록번호 앞 7자리"
                        value={birthday}
                        value2={birthdayBack}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        registrationType={true}
                        func={(value)=>{setBirthday(value)}}
                        func2={(value)=>{setBirthdayBack(value)}}
                    />
                    {cetifiedChk ? 
                        <InputItemBox
                            inputType={null}
                            addClass="cetifiedInput"
                            inputName="인증 번호"
                            placeholder="인증 번호"
                            value={cetified}
                            max={6}
                            readOnly={cetifiedComChk}
                            cetified={timerCount}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            func={(value)=>{setCetified(value)}}
                            errMsg={certifyErr}
                            btnName="재발송"
                            clickEv={()=>{cetifiedStert();setCetifiedComChk(false);setCetified("")}}
                        />
                    :""}
                    <BtnBox
                        boxType="fixed"
                        addClass={`pcBasicBtnBox row2`}
                    >
                        <BtnItem
                            addClass=""
                            contents={cetifiedChk ? "휴대폰 번호 변경" : "인증번호 발송"}
                            disabled={btnChk}
                            func={() => {
                                editPhoneFunc()
                            }}
                        />
                        <BtnItem
                            addClass="line"
                            contents={"뒤로"}
                            disabled={false}
                            func={() => {navigate(-1)}}
                        />
                    </BtnBox>
                </InputContents>
            </ContentsSection>
            <Footer pcOnly={true}/>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </>
    );
};

export default EditPhone;