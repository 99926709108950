import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { NotItems } from "component/basic/notItems";
import { setDateTime } from "js/function";
import { TableBasicTextItem, TableSection, TableTd, TableTextValItem, TableTr } from "component/basic";
import { MypageAreaTitleBox, MypageContents, MypageSection, MypageSideMenu } from "component/mypage/items";
import { MypageOrderTableMBtn } from "component/mypage/order";
import PageNation from "component/basic/pageNation";

const MyMenu = (props) => {
    const navigate = useNavigate();

    const [myMenuPage,setMyMenuPage] =  useState(1);

    const myMenuApi = useGet({
        url: `/menu/board?page=${myMenuPage}`,
        loginType:"login"
    });
    const myMenuApiData = myMenuApi?.data?.menu_boards;

    useEffect(() => {
        
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={"식단표"}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MypageContents>
                    <MypageSideMenu tapActive={7}/>
                    <MypageSection>
                        <MypageAreaTitleBox addClass="notBorder" title="식단표"/>
                        <TableSection
                            headType={true}
                            headAddClass="pcContents"
                            th={[
                                {
                                    style:{minWidth:"70%",width:"70%"},
                                    contents:`제목`
                                },
                                {
                                    style:{minWidth:"30%",width:"30%"},
                                    contents:`작성일자`
                                }
                            ]}
                        >
                            {myMenuApiData?.data && myMenuApiData?.data?.length > 0 ? 
                            <>
                            {myMenuApiData?.data?.map((item,i)=>(
                                <Fragment key={i}>
                                <TableTr addClass={`myMenuTr`}>
                                    <TableTd
                                        addClass=""
                                        style={{minWidth:"70%",width:"70%"}}
                                        func={()=>{navigate(`/mypage/menu/detail/${item.id}`)}}
                                    >
                                        <TableBasicTextItem addClass="" text={item.title||"-"}/>
                                    </TableTd>
                                    <TableTd
                                        addClass=""
                                        style={{minWidth:"30%",width:"30%"}}
                                        func={()=>{navigate(`/mypage/menu/detail/${item.id}`)}}
                                    >
                                        <TableTextValItem
                                            mName={true}
                                            name={"작성일자"}
                                            val={item.created_at ? setDateTime(item.created_at,"yymmdd","hymd") : "-"}
                                        />
                                    </TableTd>

                                    <TableTd
                                        addClass="mContents"
                                        style={{minWidth:"0",width:"100%"}}
                                    >
                                        <MypageOrderTableMBtn addClass="oColor" func={()=>{navigate(`/mypage/menu/detail/${item.id}`)}}>
                                            식단표 상세
                                        </MypageOrderTableMBtn>
                                    </TableTd>
                                </TableTr>
                                </Fragment>
                            ))}
                            </>
                            : <NotItems addClass="orderTable_notItems" text={"등록된 식단표가 없습니다."}/>}
                        </TableSection>
                        {myMenuApiData ? <PageNation perPage={5} total={myMenuApiData?.last_page} page={myMenuPage} func={(i)=>{setMyMenuPage(i)}}/> : ""}
                    </MypageSection>
                </MypageContents>
            </ContentsSection>
            <Footer/>
        </>
    );
};

export default MyMenu;