import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { moveScroll } from "js/function";
import { SubInfoTextBox, SubInfoTextSection } from "component/basic";
import { PointFixedTap } from "component/basic/tap";
import { DetailBottomInfoSection, DetailCategoriesSection, DetailCopyText, DetailSection, ProductDetailInfo, RecipeDetailItem, RecipeDetailSection } from "component/product/detail";
import { SurveyDetailNutrition, SurveyDetailTitleBox } from "component/survey/items";

const RecipeDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const copyRef = useRef(null);

    const [tap,setTap] =  useState(null);
    const [bottomInfo,setBottomInfo] =  useState("");

    const [like,setLike] =  useState(0);

    const [submitChk,setSubmitChk] =  useState(true);

    const [detailList,setDetailList] =  useState([]);

    const [apiUpdate,setApiUpdate] =  useState(0);

    const detailApi = useGet({
        url:`/recipe/detail?recipe_id=${id}&user_family_id=${sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : ""}&update=${apiUpdate}`,
        loginType:"login"
    });
    const detailApiData = detailApi?.data;
    const productData = detailApiData?.recipe;
    const recipe_ingredient_detail = detailApiData?.recipe_ingredient_detail;
    const recipe_details = detailApiData?.recipe_details;
    const recipe_ingredient_categories = detailApiData?.recipe_ingredient_categories;
    const nutrition_config = detailApiData?.nutrition_config;

    function likeFunc(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            formData.append("type", "recipe");
            formData.append("target_id", id);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/like",
                success: (data) => {
                    setApiUpdate(apiUpdate + 1);
                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    function sharingFunc(){
        copyRef?.current?.select();
        document.execCommand('copy');
        copyRef?.current?.blur();
        window.errMsg("클립보드에 복사되었습니다.");
    }

    function tapScrollEv(){
        let productDetail_categoriesSection = document.querySelectorAll(".productDetail_categoriesSection")[0]?.offsetTop,
            productDetail_recipeDetailsSection = document.querySelectorAll(".productDetail_recipeDetailsSection")[0]?.offsetTop,
            productDetail_nutritionConfigSection = document.querySelectorAll(".productDetail_nutritionConfigSection")[0]?.offsetTop,
            scrollTop = document.documentElement.scrollTop,
            headerSize = Number(document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0) + 100

        setTap(null);

        if(scrollTop + headerSize >= productDetail_nutritionConfigSection){
            setTap("productDetail_nutritionConfigSection");
        }else if(scrollTop + headerSize >= productDetail_recipeDetailsSection){
            setTap("productDetail_recipeDetailsSection");
        }else if(scrollTop + headerSize >= productDetail_categoriesSection){
            setTap("productDetail_categoriesSection");
            setBottomInfo("active");
        }else{
            setTap(null);
            setBottomInfo("");
        }
    }

    useEffect(() => {
        if(productData){
            setLike(productData?.my_like ? productData?.my_like : 0);
        }
        if(recipe_details){
            let setList = [...recipe_details];
            setList = setList?.map((item) => {
                return {
                    ...item,
                    main_img : item.recipe_detail_images?.length > 0 ? item.recipe_detail_images[0]?.image_url : null
                };
            });
            setDetailList(setList);
        }
    }, [detailApi]);

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", tapScrollEv);
            window.addEventListener("resize", tapScrollEv);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", tapScrollEv);
            window.removeEventListener("resize", tapScrollEv);
        };
    }, []);
    
    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                mBtn={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={null}
                    rightBtnsArr={[
                        {
                            type:"img",
                            func:()=>{likeFunc()},
                            addClass:"",
                            contents:like === 0 ? "/assets/images/icon/like_line.svg" : "/assets/images/icon/like_on.svg"
                        },
                        {
                            type:"img",
                            func:()=>{sharingFunc()},
                            addClass:"",
                            contents:"/assets/images/icon/sharing.svg"
                        }
                    ]}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <ProductDetailInfo
                    addClass=""
                    detailType="recipe"
                    id={productData?.id}
                    image_url={productData?.image_url}
                    people_number={productData?.people_number}
                    cooking_time={productData?.cooking_time}
                    main_disease={productData?.main_disease}

                    like={like}

                    likeFunc={()=>{likeFunc()}}
                    sharingFunc={()=>{sharingFunc()}}

                    title={productData?.title}
                    contents={productData?.contents}

                    view_count={productData?.view_count}
                    like_count={productData?.like_count}

                    relation_menu_id={productData?.relation_menu_id}
                />
                <div className="detailTapBox">
                    <PointFixedTap
                        data={[
                            {
                                id:`productDetail_categoriesSection`,
                                name:"레시피 재료"
                            },
                            {
                                id:`productDetail_recipeDetailsSection`,
                                name:"조리 순서"
                            },
                            {
                                id:`productDetail_nutritionConfigSection`,
                                name:"영양구성"
                            }
                        ]}
                        func={(name,id)=>{moveScroll(`.${id}`,Number(document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0) + 100)}}
                        nameKey={"name"}
                        valKey={"id"}
                        val={tap}
                        headerChk={true}
                    />
                </div>
                <DetailSection
                    addClass="productDetail_categoriesSection"
                >
                    <SurveyDetailTitleBox
                        title={`레시피 재료`}
                        subTitle={`필수 재료는 재료마다 색상으로 표기해두었어요.`}
                    />
                    <DetailCategoriesSection
                        addClass="row3"
                        type="recipe"
                        data={recipe_ingredient_categories}
                    />
                    <SubInfoTextSection addClass="row2">
                        <SubInfoTextBox
                            title="기본 계량법 안내"
                            textArr={[
                                {
                                    name:"1큰술 (1T)",
                                    val:"15 ml",
                                },
                                {
                                    name:"1작은술 (1t)",
                                    val:"5 ml",
                                },
                                {
                                    name:"1컵 (1C)",
                                    val:"200 ml",
                                },
                                {
                                    name:"1종이컵",
                                    val:"180 ml",
                                }
                            ]}
                        />
                        <SubInfoTextBox
                            title="계량 팁 안내"
                            textArr={[
                                {
                                    name:"1g",
                                    val:"1 ml & 1 cc"
                                },
                                {
                                    name:"1oz",
                                    val:"28.3 g"
                                },
                                {
                                    name:"1파운드",
                                    val:"0.453 kg"
                                },
                                {
                                    name:"1갤런",
                                    val:"3.78 L"
                                }
                            ]}
                        />
                    </SubInfoTextSection>
                </DetailSection>

                <DetailSection
                    addClass="productDetail_recipeDetailsSection"
                >
                    <SurveyDetailTitleBox
                        title={`조리 순서`}
                        subTitle={null}
                    />
                    <RecipeDetailSection>
                        {detailList && detailList?.map((item,i)=>(
                            <RecipeDetailItem
                                key={i}
                                idx={i}
                                id={item.id}
                                main_img={item.main_img}
                                contents={item.contents}
                                recipe_detail_images={item.recipe_detail_images}
                                func={(img,id)=>{
                                    let oldData = [...detailList];
                                    if(oldData?.filter((el) => el.id === item.id)[0]){
                                        oldData = oldData?.map((subItem) => subItem.id == item.id ? 
                                            {
                                                ...subItem,
                                                main_img: img
                                            } 
                                        : subItem)
                                    }
                                    setDetailList(oldData);
                                }}
                            />
                        ))}
                    </RecipeDetailSection>
                    <SubInfoTextSection addClass="">
                        <SubInfoTextBox
                            title="팁 또는 주의사항 안내"
                            text={productData?.cooking_tip}
                            img="/assets/images/img/cooking_tip.png"
                        />
                    </SubInfoTextSection>
                </DetailSection>

                <DetailSection
                    addClass="productDetail_nutritionConfigSection"
                >
                    <SurveyDetailTitleBox
                        title={`영양구성`}
                        subTitle={`레시피에 모든 재료를 사용했을 때 포함된 영양소에요.`}
                    />
                    <SurveyDetailNutrition
                    
                        data={[
                            {
                                color: nutrition_config && nutrition_config?.calorie > recipe_ingredient_detail?.enerc ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"kcal",
                                val:recipe_ingredient_detail?.enerc,
                                name:"칼로리"
                            },
                            {
                                color: nutrition_config && nutrition_config?.natrium > recipe_ingredient_detail?.nat ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"mg",
                                val:recipe_ingredient_detail?.nat,
                                name:"나트륨"
                            },
                            {
                                color: nutrition_config && nutrition_config?.carbohydrate > recipe_ingredient_detail?.chocdf ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:recipe_ingredient_detail?.chocdf,
                                name:"탄수화물"
                            },
                            {
                                color: nutrition_config && nutrition_config?.sugar > recipe_ingredient_detail?.sugar ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:recipe_ingredient_detail?.sugar,
                                name:"당류"
                            },
                            {
                                color: nutrition_config && nutrition_config?.fat > recipe_ingredient_detail?.fatce ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:recipe_ingredient_detail?.fatce,
                                name:"지방"
                            },
                            {
                                color: nutrition_config && nutrition_config?.saturated_fat > recipe_ingredient_detail?.fasat ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:recipe_ingredient_detail?.fasat,
                                name:"포화지방"
                            },
                            {
                                color: "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:recipe_ingredient_detail?.fatrn,
                                name:"트랜스지방"
                            },
                            {
                                color: "oColor",
                                colorChk:false,
                                type:null,
                                unit:"mg",
                                val:recipe_ingredient_detail?.chole,
                                name:"콜레스테롤"
                            },
                            {
                                color: nutrition_config && nutrition_config?.protein > recipe_ingredient_detail?.prot ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:recipe_ingredient_detail?.prot,
                                name:"단백질"
                            }
                        ]}
                    />
                </DetailSection>
            </ContentsSection>
            <DetailCopyText inputRef={copyRef}/>
            <DetailBottomInfoSection
                addClass={`pcContents ${bottomInfo}`}
                name={productData?.title}

                people_number={productData?.people_number}
                cooking_time={productData?.cooking_time}
                main_disease={productData?.main_disease}

                btnText={productData?.relation_menu_id ? "식단으로 구매하기" : ""}
                btnFunc={()=>{navigate(`/market/detail/${productData?.relation_menu_id}`)}}
            />
            <Footer/>
        </>
    );
};

export default RecipeDetail;