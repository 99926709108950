import { PageErrBox } from "component/app/pageErr";
import { BtnBox, BtnItem } from "component/basic/btns";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Private = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("token")){
            navigate(0);
        }
    }, []);

    return (
        <>
            <PageErrBox
                icon={false}
                title="로그인이 필요한 페이지 입니다."
                text=""
            />
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"로그인"}
                    disabled={false}
                    func={() => {
                        let nowUrl = new URL(window.location.href);
                        sessionStorage.setItem("setUrl",nowUrl.pathname);
                        navigate("/welcome")
                    }}
                />
            </BtnBox>
        </>
    );
};

export default Private;