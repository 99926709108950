//PageNation item
export default function PageNation(data){
    const pageCount = () => {
        const pages = [];
        let pageCount = 0;
        for (let i = data.page >= 3 ? data.page - 2 : 1; i <= data.total && pageCount < data.perPage; i++) {
            pages.push(<button type="button" key={i} onClick={()=>{data.func(i)}} className={`btn_pageNationNumber ${i == data.page ? "active" : ""}`}>{i}</button>);
            pageCount++;
        }
        return pages;
    };

    return (
        <div className={`pageNation ${data.addClass ? data.addClass : ""}`}>
            <button type="button" onClick={()=>{if(data.page > 1) data.func(Number(data.page) - 1)}} className={`btn_pageNation btn_pageNation_prev ${data.page <= 1 ? "offPn" : ""}`}><img src="/assets/images/basic/pagenation_left.svg"/></button>
            {pageCount()}
            <button type="button" onClick={()=>{if(data.page < data.total) data.func(Number(data.page) + 1)}} className={`btn_pageNation btn_pageNation_next ${data.page >= data.total ? "offPn" : ""}`}><img src="/assets/images/basic/pagenation_right.svg"/></button>
        </div>
    );
}