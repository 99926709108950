import { BtnItem } from "component/basic/btns";

function FamilyItemTextBox(data){
    return (
        <div className={`familyItem_textBox ${data.addClass||""}`}>
            <p className="familyItem_textName" dangerouslySetInnerHTML={{__html:data.name}}/>
            {data.val ? <p className="familyItem_textVal" dangerouslySetInnerHTML={{__html:data.val}}/> : ""}
        </div>
    );
}

function FamilyItem(data){
    return (
        <div className={`familyItem ${data.val === data.id || (!data.val && data.userType === "my") ? "active" : ""}`}>
            <div className="familyItem_infoBox">
                <img src={`/assets/images/icon/user_icon.svg`}/>
                <div className="familyItem_info">
                    <FamilyItemTextBox addClass="familyItem_nameBox" name={data.name} val={data.val === data.id || (!data.val && data.userType === "my") ? "현재 이용중" : ""}/>
                    {data.gender ? <FamilyItemTextBox addClass="" name="성별" val={data.gender}/> : ""}
                    {data.birth ? <FamilyItemTextBox addClass="" name="출생년도" val={data.birth}/> : ""}
                    {data.height ? <FamilyItemTextBox addClass="" name="키" val={data.height}/> : ""}
                    {data.weight ? <FamilyItemTextBox addClass="" name="몸무게" val={data.weight}/> : ""}
                </div>
            </div>
            <BtnItem
                addClass="subType"
                contents={data.val === data.id || (!data.val && data.userType === "my") ? "현재 이용중" : "변경하기"}
                disabled={data.val === data.id || (!data.val && data.userType === "my") ? true : false}
                func={() => {data.func()}}
            />
            {data.type === "familyPageItem" ?
                <BtnItem
                    addClass="subType line"
                    contents={"정보 수정"}
                    disabled={false}
                    func={() => {data.editFunc()}}
                />
            :""}
        </div>
    );
}

function FamilyItemAddBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className="familyItemAddBtn">
            <img src="/assets/images/icon/addIcon.svg"/>
            프로필 추가하기
        </button>
    );
}

export {FamilyItem,FamilyItemAddBtn};