import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { ContentsSection, InputContents } from "component/app/items";
import { Footer } from "component/elements/footer";

const FindPw = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(0);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [popupData,setPopupData] =  useState(null);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [responseSeq,setResponseSeq] =  useState("");
    
    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");
    const [pwCom,setPwCom] =  useState("");

    const [idErr,setIdErr] =  useState(null);
    const [pwErr,setPwErr] =  useState(null);
    const [pwComErr,setPwComErr] =  useState(null);

    const [name,setName] =  useState("");
    const [birthday,setBirthday] =  useState("");
    const [birthdayBack,setBirthdayBack] =  useState("");
    const [phone,setPhone] =  useState("");
    const [phoneType,setPhoneType] =  useState("");

    const [submitChk,setSubmitChk] =  useState(true);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function pageBack(){
        if(page === 0){
            navigate(-1)
        }else{
            setPage(page - 1)
        }
    }

    function pageNextFunc(){
        setBtnChk(true);
        setPwErr(null)
        setPwComErr(null)
        setIdErr(null)
                    
        setPage(page + 1);
    }

    function cetifiedStert(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("name", name);
            formData.append("birth", `${birthday}${birthdayBack}`);
            formData.append("phone", phone?.replace(/\-/g,""));
            formData.append("mobile_carrier", phoneType.val);
            formData.append("find_password", 1);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/certification`,
                success: (data) => {
                    setSubmitChk(true);

                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);
                    setLoadingBoxOpen(false);

                    setBtnChk(true);

                    setResponseSeq(data?.data?.response_seq);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function pageNext(){
        if(page == 0){
            if(submitChk){
                setSubmitChk(false);
                setLoadingBoxOpen(true);

                let formData = new FormData();
                formData.append("login_id", id);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    url: `/id/check`,
                    success: (data) => {
                        setSubmitChk(true);
                        setLoadingBoxOpen(false);
                        
                        pageNextFunc();
                    },
                    err: (data) => {
                        setSubmitChk(true);
                        if(data.data || data.alert){
                            window.errPopupOpen(data);
                        }
                        setLoadingBoxOpen(false);
                    }
                })
            }
        }else if(page == 1){
            if(submitChk){
                if(cetifiedChk){
                    setSubmitChk(false);
                    setLoadingBoxOpen(true);

                    let formData = new FormData();
                    formData.append("name", name);
                    formData.append("birth", `${birthday}${birthdayBack}`);
                    formData.append("phone", phone);
                    formData.append("mobile_carrier", phoneType.val);

                    formData.append("response_seq", responseSeq);
                    formData.append("certification_code", cetified);
                    formData.append("find_password", 1);

                    fatchSet.FatchApi({
                        type:"POST",
                        formDataItem: formData,
                        url: `/certification/check`,
                        success: (data) => {
                            setSubmitChk(true);

                            setCetifiedComChk(true);

                            setTimerCount("3:00");
                            setCertifyErr("");

                            clearInterval(sval.current);
                            sval.current = null;

                            setLoadingBoxOpen(false);
                            
                            if(data?.data?.user){
                                if(data?.data?.user.provider){
                                    setCetifiedComChk(false);setCetified("")
                                    setPopupData({
                                        addClass:null,
                                        title:"비밀번호 찾기 불가",
                                        text:`${data?.data?.user.provider} 소셜로그인으로 가입되었습니다.`,
                                        closeType:false,
                                        closeFunc:(e) => {},
                                        btnFunc0:(e)=>{navigate(-1)},
                                        btn0:"로그인",
                                        btn0Type:"",
                                        btn1:"",
                                        btn1Type:"",
                                        btnFunc1:()=>{}
                                    });
                                }else{
                                    pageNextFunc();
                                }
                            }else{
                                setCetifiedComChk(false);setCetified("");
                                setPopupData({
                                    addClass:null,
                                    title:"비밀번호 찾기 불가",
                                    text:"입력하신 회원정보로 가입된 계정이 없습니다.",
                                    closeType:false,
                                    closeFunc:(e) => {},
                                    btnFunc0:(e)=>{navigate(-1)},
                                    btn0:"로그인",
                                    btn0Type:"",
                                    btn1:"",
                                    btn1Type:"",
                                    btnFunc1:()=>{}
                                });
                            }
                        },
                        err: (data) => {
                            setSubmitChk(true);
                            if(data.alert){
                                setCertifyErr(data.alert);
                            }
                            setLoadingBoxOpen(false);
                        }
                    })
                }else{
                    cetifiedStert();setCetifiedComChk(false);setCetified("")
                }
            }
        }else if(page == 2){
            if(submitChk){
                setSubmitChk(false);
                setLoadingBoxOpen(true);

                let formData = new FormData();
                formData.append("password", pw);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    url: `/password/modify`,
                    success: (data) => {
                        setSubmitChk(true);
                        setLoadingBoxOpen(false);
                        
                        setPopupData({
                            addClass:null,
                            title:"비밀번호 변경 완료",
                            text:"새로운 비밀번호로 로그인해 주세요.",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{
                                navigate("/member/login")
                            },
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    },
                    err: (data) => {
                        setSubmitChk(true);
                        if(data.data || data.alert){
                            window.errPopupOpen(data);
                        }
                        setLoadingBoxOpen(false);
                    }
                })
            }
        }
    }

    useEffect(() => {
        let ragChk = /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]/g;
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(page == 1){
            if(name.length > 0 
            && ragChk.test(`${birthday}${birthdayBack}`)
            && phoneChk.test(phone?.replace(/\-/g,""))
            && phoneType.val
            && (cetifiedChk ? cetified.length == 6 : true)){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }else{
            setBtnChk(false)
        }
    }, [name,birthday,birthdayBack,phone,phoneType,cetified,cetifiedChk,page]);

    useEffect(() => {
        let idType = /(^(?=.*[a-zA-Z])(?=.*[0-9]).{4,20}$)|(^(?=.*[a-zA-Z]).{4,20}$)/;
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        if(!idType.test(id) && id !== ""){
            setIdErr("영문 혹은 영문, 숫자 조합 4-20자로 입력해 주세요.")
        }else{
            setIdErr(null)
        }

        if(pw !== pwCom && pw !== "" && pwCom !== ""){
            setPwComErr("비밀번호가 일치하지 않습니다.")
        }else{
            setPwComErr(null)
        }

        if(!pwType.test(pw) && pw !== ""){
            setPwErr("영문, 숫자, 특수문자 조합 8-20자로 설정해 주세요.")
        }else{
            setPwErr(null)
        }

        if(page == 0){
            if(
                idType.test(id) && id !== ""
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 2){
            if(
                pwType.test(pw) && pw !== ""
                && pw == pwCom && pwType.test(pw)
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [id,pw,pwCom]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={false}
                mBtn_r2={true}
                pcFooter={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <InputContents
                    title={page === 2 ? "비밀번호 재설정" : "비밀번호 찾기"}
                    subTitle={page === 0 ? null : page === 1 ? "비밀번호를 찾기 위해선 본인인증이 필요해요." : "변경하실 비밀번호를 입력해주세요."}
                >
                    {
                        page === 0 ? 
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="아이디"
                                placeholder="아이디를 입력하세요"
                                value={id}
                                max={20}
                                regexp={/[^a-zA-Z0-9]/gi}
                                errMsg={idErr}
                                func={(value)=>{setId(value)}}
                            />
                        </>
                        : page === 1 ? 
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="휴대폰 번호"
                                placeholder="‘-’ 없이 입력"
                                value={phone}
                                max={13}
                                phoneSetting={true}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setPhone(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("")}}
                            />
                            <CustomSelect
                                addClass="notName"
                                inputName="통신사"
                                placeholder="선택"
                                value={phoneType?.val}
                                func={(name,val) => {
                                    let dataSet = {
                                        val:val,
                                        text:name
                                    }
                                    setPhoneType(dataSet);
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="name"
                                options={[
                                    {id:1,name:"SKT"},
                                    {id:2,name:"KT"},
                                    {id:3,name:"LGU+"},
                                    {id:5,name:"알뜰폰(SKT)"},
                                    {id:6,name:"알뜰폰(KT)"},
                                    {id:7,name:"알뜰폰(LGU+)"}
                                ]}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="이름"
                                placeholder="이름"
                                value={name}
                                max={null}
                                regexp={null}
                                func={(value)=>{setName(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="주민등록번호 앞 7자리"
                                placeholder="주민등록번호 앞 7자리"
                                value={birthday}
                                value2={birthdayBack}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                registrationType={true}
                                func={(value)=>{setBirthday(value)}}
                                func2={(value)=>{setBirthdayBack(value)}}
                            />
                            {cetifiedChk ? 
                                <InputItemBox
                                    inputType={null}
                                    addClass="cetifiedInput"
                                    inputName="인증 번호"
                                    placeholder="인증 번호"
                                    value={cetified}
                                    max={6}
                                    readOnly={cetifiedComChk}
                                    cetified={timerCount}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setCetified(value)}}
                                    errMsg={certifyErr}
                                    btnName="재발송"
                                    clickEv={()=>{cetifiedStert();setCetifiedComChk(false);setCetified("")}}
                                />
                            :""}
                        </>
                    : page === 2 ? 
                        <>
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="새 비밀번호"
                            placeholder="영문, 숫자, 특수문자 조합 8~20자"
                            value={pw}
                            pwChType={true}
                            max={20}
                            regexp={null}
                            errMsg={pwErr}
                            func={(value)=>{setPw(value)}}
                        />
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="새 비밀번호 확인"
                            placeholder="영문, 숫자, 특수문자 조합 8~20자"
                            value={pwCom}
                            pwChType={true}
                            max={20}
                            regexp={null}
                            errMsg={pwComErr}
                            func={(value)=>{setPwCom(value)}}
                        />    
                        </>           
                    : ""}
                    <BtnBox
                        boxType="fixed"
                        addClass={`pcBasicBtnBox row2`}
                    >
                        <BtnItem
                            addClass=""
                            contents={page === 0 ? "다음으로" : page === 1 ? cetifiedChk ? "비밀번호 찾기" : "인증번호 발송" : "새 비밀번호 설정"}
                            disabled={btnChk}
                            func={() => {
                                pageNext()
                            }}
                        />
                        <BtnItem
                            addClass="line"
                            contents={"뒤로"}
                            disabled={false}
                            func={() => {
                                pageBack()
                            }}
                        />
                    </BtnBox>
                </InputContents>
            </ContentsSection>
            <Footer pcOnly={true}/>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </>
    );
};

export default FindPw;