import { useState } from "react";

//상품 수량
export default function CountInput(data){
    const [count,setCount] = useState(data.val ? data.val : 1);

    function setCountUp(){
        if(data.qty && (data.optionQty ? Math.min(Number(data.optionQty),Number(data.qty)) : data.qty) < (data.maxQty||data.optionMaxQty ? Number(Math.min(data.maxQty||99,data.optionMaxQty||99)) : 99)){
            if(count < (data.optionQty ? Math.min(Number(data.optionQty),Number(data.qty)) : data.qty)){
                data.func(countVal(data.val) + 1);
            }else if(data.excessFunc){
                data.excessFunc()
            }
        }else{
            if(count < (data.maxQty||data.optionMaxQty ? Number(Math.min(data.maxQty||99,data.optionMaxQty||99)) : 99)){
                data.func(countVal(data.val) + 1);
            }else if(data.excessFunc){
                window.errMsg("최대 수량입니다.");
                data.excessFunc()
            }
        }
    }

    function setCountDown(){
        if(count > 1){
            data.func(countVal(data.val) - 1);
        }else{
            if(data.delType){
                data.delFunc();
            }else{
                setCount(1);
                data.func(1);
            }
        }
    }

    function countVal(e){
        let value = e;

        return value ? value : "";
    }

    return (
        <div className={`countBox ${data.addClass||""}`}>
            <button type="button" onClick={() => setCountDown()} className="btn_m"><img src={`/assets/images/basic/${count > 1 ? "ms" : "ms"}.svg`}/></button>
            <input type="text" value={countVal(data.val)} readOnly className="countInput"/>
            <button type="button" onClick={() => setCountUp()} className="btn_p"><img src={`/assets/images/basic/${count < 99 && count < (data.optionQty ? Math.min(Number(data.optionQty),Number(data.qty)) : data.qty) ? "ps" : "ps"}.svg`}/></button>
        </div>
    );
}