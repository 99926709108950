import { comFormat } from "js/function";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { FreeMode } from 'swiper/modules';
import { NotItems } from "component/basic/notItems";

function SubProductList(data){
    return (
        <div className={`subProductListSection ${data.addClass||""}`}>
            <div className="subProductList">
                <div className="subProductList_titleBox">
                    <div className="subProductList_titleBoxItem">
                        <h1 className="subProductList_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                        {data.text ? 
                            <p className="subProductList_text" dangerouslySetInnerHTML={{__html:data.text}}/>
                        :""}
                    </div>
                    <div className="subProductList_tapArea">
                        {data.allBtn ? 
                            <button type="button" onClick={()=>{data.func()}} className={`subProductList_allBtn mContents flexItem ${data.btnAddClass||""}`}>
                                {data.allBtnText||"전체보기"}
                                <img src="/assets/images/basic/move_min.svg"/>
                            </button>
                        :""}
                        {data.tap ? 
                            <div className="subProductList_tapBox">
                                {data.tap?.map((item,i)=>(
                                    <button type="button" key={i} onClick={()=>{data.tapFunc(item.id)}} className={`subProductList_tap ${data.tapVal === item.id ? "active" : ""}`} dangerouslySetInnerHTML={{__html:item.name}}/>
                                ))}
                            </div>
                        :""}
                        {data.allBtn ? 
                            <button type="button" onClick={()=>{data.func()}} className={`subProductList_allBtn pcContents ${data.btnAddClass||""}`}>
                                {data.allBtnText||"전체보기"}
                                <img src="/assets/images/basic/move_min.svg"/>
                            </button>
                        :""}
                    </div>
                </div>
                <div className={`subProductList_itemBox ${data.itemBoxAddClass||""}`}>
                    {data.children}
                </div>
            </div>
        </div>
    );
}

function SubProductListSlide(data){

    return (
        <div className={`subProductListSlide ${data.addClass||""} ${data.data && data.data?.length > 0 ? "" : "subProductListSlide_notItem"}`}>
        {data.data && data.data?.length > 0 ? 
            <Swiper
                spaceBetween={20}
                slidesPerView={4.5}
                autoHeight={false}
                loop={false}
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                    1024: {
                    slidesPerView: 4.5
                    },
                    1: {
                        slidesPerView: "auto"
                    }
                }}
            >
                {data.data && data.data?.map((item, i) => (
                    <SwiperSlide key={i}>
                        {data.type === "recipe" ? 
                            <ProductItem
                                type="recipe"
                                id={item.id}
                                image_url={item.image_url}
                                product_name={item.title}
                                people_number={item.people_number}
                                cooking_time={item.cooking_time}
                                main_disease={item.main_disease}
                                view_count={item.view_count}
                                like_count={item.like_count}
                            />
                        :data.type === "market" ? 
                            <ProductItem
                                type="market"
                                id={item.id}
                                image_url={item.main_image_url}
                                product_name={item.name}
                                summary={item.summary}
                                price={item.price}
                                discount_price={item.discount_price}
                            />
                        :""}
                    </SwiperSlide>
                ))}
            </Swiper>
            :
            <NotItems text={`${data.type === "recipe" ? "레시피 목록이" : "상품 목록이"} 없습니다.`}/>
            }
        </div>
    );
}

function ProductListBoxMin(data){
    return (
        <div className={`productListBoxMin ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function ProductListBox(data){
    return (
        <div className={`productListBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function ProductCaption(data){
    return (
        <p className={`productCaption ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

function ProductListCount(data){
    return (
        <div className="productListCountBox">
            <img src={`/assets/images/icon/${data.type}.svg`}/>
            <p className={`productListCount ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function ProductItem(data){
    const navigate = useNavigate();

    return (
        <div className="productItem">
            <div className="productItem_info_img">
                <div className="productItem_img backgroundImg" onClick={()=>{
                    if(data.type === "recipe"){
                        navigate(`/recipe/detail/${data.id}`)
                    }else if(data.type === "market"){
                        navigate(`/market/detail/${data.id}`)
                    }
                }} style={{backgroundImage:`url('${data.image_url}')`}}></div>
                {data.pageType === "myRecipe" ? 
                    <button type="button" onClick={()=>{data.likeFunc(data.id)}} className={`btn_productItem_listLike`}>
                        <img src={`/assets/images/icon/${data.is_like == 0 ? "recipe_like_off" : "recipe_like_on"}.svg`}/>
                    </button>
                :""}
            </div>
            <div className="productItem_info" onClick={()=>{
                if(data.type === "recipe"){
                    navigate(`/recipe/detail/${data.id}`)
                }else if(data.type === "market"){
                    navigate(`/market/detail/${data.id}`)
                }
            }}>
                {data.type === "recipe" ? 
                    <div className="productItem_captionBox">
                        {data.people_number||data.people_number === 0 ? <ProductCaption text={data.people_number} addClass="rColor"/> : ""}
                        {data.cooking_time||data.cooking_time === 0 ? <ProductCaption text={`${data.cooking_time}분`} addClass="rColor"/> : ""}
                        {data.main_disease||data.main_disease === 0 ? <ProductCaption text={data.main_disease} addClass=""/> : ""}
                    </div>
                :""}
                {data.type === "market" ? 
                    <h1 className="productItem_summary" dangerouslySetInnerHTML={{__html:data.summary}}/>
                :""}
                <h1 className="productItem_name" dangerouslySetInnerHTML={{__html:data.product_name}}/>
                {data.type === "market" ? 
                    <div className="productItem_priceBox">
                        <h2 className="productItem_price" dangerouslySetInnerHTML={{__html:data.discount_price ? comFormat((Number(data.price)||0) - (Number(data.discount_price)||0)) : comFormat(data.price)}}/>
                        {data.discount_price ? 
                            <p className="productItem_basicPrice" dangerouslySetInnerHTML={{__html:comFormat(data.price)}}/>
                        :""}
                    </div>
                :""}
                {data.type === "recipe" ? 
                    <div className="productItem_listCountArea">
                        <ProductListCount type="like" text={data.like_count ? comFormat(data.like_count) : 0}/>
                        <ProductListCount type="view" text={data.view_count ? comFormat(data.view_count) : 0}/>
                    </div>
                :""}
                {data.review ? 
                    <div className="productItem_listReview">
                        <div vreview-product-id={data.id}></div>
                    </div>
                :""}
            </div>
        </div>
    );
}
//레시피
{/* <ProductItem
    type="recipe"
    id={item.id}
    image_url={item.image_url}
    product_name={item.title}
    people_number={item.people_number}
    cooking_time={item.cooking_time}
    cooking_time={item.cooking_time}
    main_disease={item.main_disease}
    view_count={item.view_count}
    like_count={item.like_count}
/> */}
//마켓
{/* <ProductItem
    type="market"
    id={item.id}
    image_url={item.main_image_url}
    product_name={item.name}
    summary={item.summary}
    price={item.price}
    discount_price={item.discount_price}
/> */}

function ProductListPage(data){
    return (
        <div className={`productListPage ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function ListLoading(data){
    return (
        <div className={`listLoading ${data.addClass||""}`} style={data.view ? {display:"flex"} : {display:"none"}}>
            <img src="/assets/images/icon/list_loading.svg"/>
        </div>
    );
}

export {SubProductList,ProductListBoxMin,ProductListBox,ProductCaption,ProductListCount,SubProductListSlide,ProductItem,ProductListPage,ListLoading};