import { useEffect, useRef, useState } from "react";

//tap item
function Tap(data){
    return (
        <div className={`tapBox ${data.addClass ? data.addClass : ""}`}>
            {data.allText ? 
            <button type="button" onClick={()=>{data.func(null,null)}} className={`tapItem ${data.val ? "" : "active"}`} dangerouslySetInnerHTML={{__html:data.allText}}></button>
            :""}
            {data.data?.map((item, i) => (
                <button type="button" key={i} onClick={()=>{data.func(item[data.nameKey],item[data.valKey])}} className={`tapItem ${data.val == item[data.valKey] ? "active" : ""}`} dangerouslySetInnerHTML={{__html:item[data.nameKey]}}></button>
            ))}
        </div>
    );
}

function SubTap(data){
    return (
        <div className={`subTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`subTapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

function SubLine(data){
    return (
        <div className={`subLine ${data.addClass ? data.addClass : ""}`} style={{margin:data.margin}}></div>
    );
}

function PopupTap(data){
    return (
        <div className={`popupTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`popupTapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

function PointFixedTap(data){
    const fixedTargetRef = useRef(null);
    const fixedBoxRef = useRef(null);
    const [fixedCategory, setFixedCategory] = useState("");
    const [headerSize, setHeaderSize] = useState(0);

    function handleScroll(){
        if(data.minSize ? window.innerWidth >= data.minSize : true){
            let header = document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0;

            if(data.headerChk ? (window.scrollY + header > (window.pageYOffset + fixedBoxRef?.current?.getBoundingClientRect().top)) : (window.scrollY > (window.pageYOffset + fixedBoxRef?.current?.getBoundingClientRect().top))){
                setFixedCategory(data.headerChk ? "headerFixedItem" : "fixedItem");
            }else{
                setFixedCategory("");
            }
        }

        if(data.headerChk){
            setHeaderSize(Number(document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0))
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleScroll);
        };
    }, []);

    return (
        <div className={`fixedTapPoint ${data.boxAddClass ? data.boxAddClass : ""}`} ref={fixedBoxRef}>
            <div className={`fixedTapSection ${data.addClass ? data.addClass : ""} ${fixedCategory}`} style={fixedCategory === "headerFixedItem" || fixedCategory === "fixedItem" ? {top:`${headerSize}px`} : {}} ref={fixedTargetRef}>
                <Tap
                    data={data.data}
                    nameKey={data.nameKey}
                    valKey={data.valKey}
                    val={data.val}
                    func={data.func}
                />
            </div>
        </div>
    );
}

export {Tap, SubTap, SubLine, PopupTap, PointFixedTap}