import { TableMinBtn, TableSection, TableTd, TableBasicTextItem, TableTr } from "component/basic";
import { NotItems } from "component/basic/notItems";
import { OrderProduct } from "component/product/order";
import { comFormat, orderListType, setDateTime } from "js/function";
import { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function MypageOrderTableMBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`mypageOrderTableMBtn ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
            {data.children}
        </button>
    );
}

function MypageOrderTable(data){
    const navigate = useNavigate();

    return (
        <TableSection
            headType={true}
            headAddClass="pcContents"
            th={data.type === "order" ?  [
                {
                    style:{minWidth:"12%",width:"12%"},
                    contents:`주문 일자`
                },
                {
                    style:{minWidth:"31%",width:"31%"},
                    contents:`주문 정보`
                },
                {
                    style:{minWidth:"21%",width:"21%"},
                    contents:`주문번호`
                },
                {
                    style:{minWidth:"18%",width:"18%"},
                    contents:`주문 금액`
                },
                {
                    style:{minWidth:"18%",width:"18%"},
                    contents:`주문 상태`
                }
            ] : [
                {
                    style:{minWidth:"12%",width:"12%"},
                    contents:`주문 일자`
                },
                {
                    style:{minWidth:"31%",width:"31%"},
                    contents:`주문 정보`
                },
                {
                    style:{minWidth:"21%",width:"21%"},
                    contents:`주문번호`
                },
                {
                    style:{minWidth:"12%",width:"12%"},
                    contents:`회차`
                },
                {
                    style:{minWidth:"12%",width:"12%"},
                    contents:`주문 금액`
                },
                {
                    style:{minWidth:"12%",width:"12%"},
                    contents:`주문 상태`
                }
            ]}
        >
            {data.items && data.items?.length > 0 ? 
            <>
            {data.items?.map((item,i)=>(
                <Fragment key={i}>
                <TableTr addClass={`pcContents mypageOrderTableTr`}>
                    <TableTd
                        addClass=""
                        style={{minWidth:"12%",width:"12%"}}
                        func={()=>{if(data.pageType !== "detail"){navigate(`/mypage/order/detail/${item.id}/${data.type}`)}}}
                    >
                        <TableBasicTextItem text={setDateTime(item.created_at,"yymmdd","hymd")}/>
                    </TableTd>
                    <TableTd
                        addClass="alignLeft"
                        style={{minWidth:"31%",width:"31%"}}
                        func={()=>{if(data.pageType !== "detail"){navigate(`/mypage/order/detail/${item.id}/${data.type}`)}}}
                    >
                        <div className="mypageOrderProductBox">
                            <OrderProduct
                                image_url={item?.main_image_url}
                                name={item?.name}
                                options={item?.option_name}
                            />
                        </div>
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"21%",width:"21%"}}
                        func={()=>{if(data.pageType !== "detail"){navigate(`/mypage/order/detail/${item.id}/${data.type}`)}}}
                    >
                        <TableBasicTextItem text={item.order_number}/>
                    </TableTd>
                    {data.type === "subscribe" ? 
                        <TableTd
                            addClass=""
                            style={data.type === "order" ? {minWidth:"18%",width:"18%"} : {minWidth:"12%",width:"12%"}}
                            func={()=>{if(data.pageType !== "detail"){navigate(`/mypage/order/detail/${item.id}/${data.type}`)}}}
                        >
                            <TableBasicTextItem text={`${item.current_count||1}회차 / ${item.total_count}회차`}/>
                        </TableTd>
                    :""}
                    <TableTd
                        addClass=""
                        style={data.type === "order" ? {minWidth:"18%",width:"18%"} : {minWidth:"12%",width:"12%"}}
                        func={()=>{if(data.pageType !== "detail"){navigate(`/mypage/order/detail/${item.id}/${data.type}`)}}}
                    >
                        <TableBasicTextItem text={`${comFormat(item.payment_price)}원`}/>
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={data.type === "order" ? {minWidth:"18%",width:"18%"} : {minWidth:"12%",width:"12%"}}
                        func={null}
                    >
                        <TableBasicTextItem addClass="oTextColor" text={`<b>${data.type === "order" ? orderListType("order",item.status) : orderListType("subscribe",item.status,item.current_count)}</b>`}/>
                        {data.type === "order" ?
                            <>
                            {item.status == 0 || item.status == 1 ? //결제 대기, 결제 완료
                            <>
                                <TableMinBtn
                                    func={()=>{
                                        data.orderCancel(item.id)
                                    }}
                                >
                                    주문 취소 접수
                                </TableMinBtn>
                            </>
                            :""}
                            {item.status == 3 || item.status == 4 ? //배송 중
                            <>
                                <TableMinBtn
                                    func={()=>{
                                        data.orderDelivery(item.logistics_company,item.tracking_number)
                                    }}
                                >
                                    배송현황 조회
                                </TableMinBtn>
                            </>
                            :""}
                            {item.status == 5 ? //배송 완료
                            <>
                                <TableMinBtn
                                    func={()=>{
                                        data.orderRefund(item.id)
                                    }}
                                >
                                    교환/환불 접수
                                </TableMinBtn>
                            </>
                            :""}
                            </>
                        :
                            <>
                            {item.status == 0 || item.status == 1 ? //결제 대기, 결제 완료
                            <>
                                <TableMinBtn
                                    func={()=>{
                                        data.orderCancel(item.id)
                                    }}
                                >
                                    주문 취소 접수
                                </TableMinBtn>
                            </>
                            :""}
                            {item.status == 3 || item.status == 4 || item.status == 11 ? //배송 중 | 교환 배송중
                            <>
                                <TableMinBtn
                                    func={()=>{
                                        data.orderDelivery(item.logistics_company,item.tracking_number)
                                    }}
                                >
                                    배송현황 조회
                                </TableMinBtn>
                            </>
                            :""}
                            {item.status == 5 || item.status == 12 || item.status == 17  ? //배송 완료 | 교환 완료 | 환불 완료
                            <>
                                <TableMinBtn
                                    func={()=>{
                                        data.orderPeriod(item.id,item.periods,item.week_count,item.day_count)
                                    }}
                                >
                                    기간변경/일시정지
                                </TableMinBtn>
                            </>
                            :""}
                            </>
                        }
                    </TableTd>
                </TableTr>
                <TableTr key={i} addClass={`mContents mypageOrderTableTr`}>
                    <TableTd
                        addClass=""
                        style={{minWidth:"0",width:"100%"}}
                        func={null}
                    >
                        <TableBasicTextItem addClass="" text={`<b>${data.type === "order" ? orderListType("order",item.status) : orderListType("subscribe",item.status,item.current_count||1)}</b>`}/>
                    </TableTd>
                    <TableTd
                        addClass="alignLeft"
                        style={{minWidth:"0",width:"100%"}}
                        func={null}
                    >
                        <div className="mypageOrderProductBox">
                            <OrderProduct
                                image_url={item?.main_image_url}
                                name={item?.name}
                                options={item?.option_name}
                                price={`${comFormat(item.payment_price)}원`}
                            />
                        </div>
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"0",width:"100%"}}
                        func={null}
                    >
                        <div className="mypageOrder_flexItemBox">
                            <TableBasicTextItem text={setDateTime(item.created_at,"yymmdd","hymd")}/>
                            <div className="mypageOrder_flexItemSubBox">
                                <TableBasicTextItem text={`주문번호: ${item.order_number}`}/>
                                {data.type === "subscribe" ? 
                                    <TableBasicTextItem text={`${item.current_count||1}회차 / ${item.total_count}회차`}/>
                                :""}
                            </div>
                        </div>
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"0",width:"100%"}}
                        func={null}
                    >
                        <div className="mypageOrderBtnBox">
                            {data.pageType !== "detail" ? 
                            <MypageOrderTableMBtn addClass="oColor" func={()=>{navigate(`/mypage/order/detail/${item.id}/${data.type}`)}}>
                                주문 상세
                            </MypageOrderTableMBtn>
                            :""}
                            {data.type === "order" ?
                                <>
                                {item.status == 0 || item.status == 1 ? //결제 대기, 결제 완료
                                <>
                                    <MypageOrderTableMBtn
                                        func={()=>{
                                            data.orderCancel(item.id)
                                        }}
                                    >
                                        주문 취소 접수
                                    </MypageOrderTableMBtn>
                                </>
                                :""}
                                {item.status == 3 || item.status == 4 ? //배송 중
                                <>
                                    <MypageOrderTableMBtn
                                        func={()=>{
                                            data.orderDelivery(item.logistics_company,item.tracking_number)
                                        }}
                                    >
                                        배송현황 조회
                                    </MypageOrderTableMBtn>
                                </>
                                :""}
                                {item.status == 5 ? //배송 완료
                                <>
                                    <MypageOrderTableMBtn
                                        func={()=>{
                                            data.orderRefund(item.id)
                                        }}
                                    >
                                        교환/환불 접수
                                    </MypageOrderTableMBtn>
                                </>
                                :""}
                                </>
                            :
                                <>
                                {item.status == 0 || item.status == 1 ? //결제 대기, 결제 완료
                                <>
                                    <MypageOrderTableMBtn
                                        func={()=>{
                                            data.orderCancel(item.id)
                                        }}
                                    >
                                        주문 취소 접수
                                    </MypageOrderTableMBtn>
                                </>
                                :""}
                                {item.status == 3 || item.status == 4 || item.status == 11 ? //배송 중 | 교환 배송중
                                <>
                                    <MypageOrderTableMBtn
                                        func={()=>{
                                            data.orderDelivery(item.logistics_company,item.tracking_number)
                                        }}
                                    >
                                        배송현황 조회
                                    </MypageOrderTableMBtn>
                                </>
                                :""}
                                {item.status == 5 || item.status == 12 || item.status == 17  ? //배송 완료 | 교환 완료 | 환불 완료
                                <>
                                    <MypageOrderTableMBtn
                                        func={()=>{
                                            data.orderPeriod(item.id,item.periods,item.week_count,item.day_count)
                                        }}
                                    >
                                        기간변경/일시정지
                                    </MypageOrderTableMBtn>
                                </>
                                :""}
                                </>
                            }
                        </div>
                    </TableTd>
                </TableTr>
                </Fragment>
            ))}
            </>
            : <NotItems addClass="orderTable_notItems" img="/assets/images/icon/notCart.svg" text={data.notItemText}/>}
        </TableSection>
    );
}

function MyOrderPaymentInfoTextBox(data){
    return (
        <div className={`myOrderPaymentInfoTextBox ${data.addClass||""}`}>
            <p className={`myOrderPaymentInfoText_name ${data.textAddClass||""}`} dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className={`myOrderPaymentInfoText_val ${data.textAddClass||""}`} dangerouslySetInnerHTML={{__html:data.val}}/>
        </div>
    );
}

function MyOrderPaymentInfoTextArea(data){
    return (
        <div className={`myOrderPaymentInfoTextArea ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MyOrderPaymentInfoTextSection(data){
    return (
        <div className={`myOrderPaymentInfoTextSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MyOrderPaymentInfo(data){
    return (
        <div className={`myOrderPaymentInfo ${data.addClass||""}`}>
            <MyOrderPaymentInfoTextSection>
                <MyOrderPaymentInfoTextArea addClass="pcContents">
                    <MyOrderPaymentInfoTextBox
                        addClass="paymentMainTextBox"
                        name={"총 주문 금액"}
                        val={`<b>${data.total_price ? comFormat(Number(data.total_price) + (data.delivery_fee ? Number(data.delivery_fee) : 0)) : 0}원</b>`}
                    />
                </MyOrderPaymentInfoTextArea>

                <MyOrderPaymentInfoTextArea addClass="myOrderPaymentInfoTextCaption pcContents">
                    <img src="/assets/images/icon/orderDetail_ps.svg"/>
                </MyOrderPaymentInfoTextArea>

                <MyOrderPaymentInfoTextArea  addClass="pcContents">
                    <MyOrderPaymentInfoTextBox
                        addClass="paymentMainTextBox"
                        name={"총 할인 금액"}
                        val={`<b>-${data.total_price && data.payment_price ? comFormat((Number(data.total_price) + Number(data.delivery_fee)) - Number(data.payment_price)) : 0}원</b>`}
                    />
                </MyOrderPaymentInfoTextArea>

                <MyOrderPaymentInfoTextArea addClass="myOrderPaymentInfoTextCaption pcContents">
                    <img src="/assets/images/icon/orderDetail_ms.svg"/>
                </MyOrderPaymentInfoTextArea>

                <MyOrderPaymentInfoTextArea addClass="paymentArea">
                    <MyOrderPaymentInfoTextBox
                        addClass="paymentMainTextBox"
                        textAddClass="oTextColor"
                        name={"<b>최종 결제금액</b>"}
                        val={`<b>${data.payment_price ? comFormat(data.payment_price) : 0}원</b>`}
                    />
                </MyOrderPaymentInfoTextArea>
            </MyOrderPaymentInfoTextSection>

            <MyOrderPaymentInfoTextSection>
                <MyOrderPaymentInfoTextArea>
                    <MyOrderPaymentInfoTextBox
                        name={"상품 금액"}
                        val={`${data.total_price ? comFormat(data.total_price) : 0}원`}
                    />
                    <MyOrderPaymentInfoTextBox
                        name={"배송비"}
                        val={`${data.delivery_fee ? comFormat(data.delivery_fee) : 0}원`}
                    />
                    <MyOrderPaymentInfoTextBox
                        addClass="mContents flexItem"
                        name={"상품 할인"}
                        val={`-${data.total_price && data.payment_price ? comFormat((Number(data.total_price) + Number(data.delivery_fee)) - Number(data.payment_price)) : 0}원`}
                    />
                </MyOrderPaymentInfoTextArea>

                <MyOrderPaymentInfoTextArea addClass="myOrderPaymentInfoTextCaption pcContents"/>

                <MyOrderPaymentInfoTextArea addClass="pcContents">
                    <MyOrderPaymentInfoTextBox
                        name={"상품 할인"}
                        val={`-${data.total_price && data.payment_price ? comFormat((Number(data.total_price) + Number(data.delivery_fee)) - Number(data.payment_price)) : 0}원`}
                    />
                </MyOrderPaymentInfoTextArea>

                <MyOrderPaymentInfoTextArea addClass="myOrderPaymentInfoTextCaption pcContents"/>

                <MyOrderPaymentInfoTextArea>
                    <MyOrderPaymentInfoTextBox
                        name={"결제 수단"}
                        val={data.payment_method === "CARD" ? "카드" : data.payment_method === "VBANK" ? "가상계좌" : "-"}
                    />
                    <MyOrderPaymentInfoTextBox
                        name={"결제 일시"}
                        val={data.payment_date ? setDateTime(data.payment_date,"yymmddhhtt","hymd") : "-"}
                    />
                </MyOrderPaymentInfoTextArea>
            </MyOrderPaymentInfoTextSection>
        </div>
    );
}


function MyOrderDelivery(data){
    const formRef = useRef(null);

    useEffect(()=>{
        if(data.submit){
            formRef?.current?.submit()
        }
        setTimeout(() => {
            data.submitFunc();
        }, 100);
    }, [data.submit]);

    return (
        <div className="myOrderDeliveryArea">
            <form action="https://info.sweettracker.co.kr/tracking/5" ref={formRef} method="post" target="_blank">
                <div className="form-group">
                <input type="text" className="form-control" value={data.t_key||""} id="t_key" readOnly={true} name="t_key" placeholder=""/>
                </div>
                <div className="form-group">
                <input type="text" className="form-control" value={data.logistics_company||""} name="t_code" readOnly={true} id="t_code" placeholder=""/>
                </div>
                <div className="form-group">
                <input type="text" className="form-control" value={data.tracking_number||""} name="t_invoice" readOnly={true} id="t_invoice" placeholder=""/>
                </div>
            </form>
        </div>
    );
}

export {MypageOrderTable,MyOrderPaymentInfo,MypageOrderTableMBtn,MyOrderDelivery};