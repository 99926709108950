import { useEffect, useRef, useState } from "react";

function FilterSection(data){
    return (
        <div className={`filterSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function FilterArea(data){
    return (
        <div className={`filterArea ${data.addClass||""}`}>
            {data.title ? <h1 className={`filterAreaTitle`} dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
            {data.children}
        </div>
    );
}

function FilterTapItem(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`filterTapItem ${data.val === data.id ? "active" : ""} ${data.addClass||""}`} disabled={data.disabled ? data.disabled : false}>
            <p className={`filterTapName`} dangerouslySetInnerHTML={{__html:data.name}}/>
            {data.subData ? <p className={`filterTapSubData`} dangerouslySetInnerHTML={{__html:data.subData}}/> : ""}
        </button>
    );
}

function FilterSh(data){
    const inputItem = useRef(null);

    function valSetting(e){
        let value = e;

        if(value){
            if(data.regexp){
                value = value.replace(data.regexp,"");
            }
        }
        return value ? value : "";
    }

    useEffect(()=>{
        inputItem.current.value = data.value ? valSetting(data.value) : "";
    },[data.pageSetting])

    return (
        <div className={`filterShItem ${data.value !== "" && data.value ? "active" : ""} ${data.boxAddClass && data.boxAddClass !== "" ? data.boxAddClass : ""}`}>
            <div className={`filterShBox ${data.addClass||""}`}>
                <input 
                    ref={inputItem} 
                    type={"text"} 
                    className="filterSh"
                    
                    value={valSetting(data.value)}
                    placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""}
                    
                    onInput={(e) => {data.func(valSetting(e.target.value))}}
                    onPaste={(e) => {data.func(valSetting(e.target.value))}}
                    onChange={(e) => {data.func(valSetting(e.target.value))}}
                    onKeyUp={(e) => {data.func(valSetting(e.target.value))}}

                    readOnly={data.readOnly ? data.readOnly : false}
                    disabled={data.disabled ? data.disabled : false}
                />
                <button type="button" onClick={()=>{data.btnFunc()}} className="btn_filterSh">
                    <img src="/assets/images/basic/sh_icon.svg"/>
                </button>
            </div>
        </div>
    );
}

function FilterMoSection(data){
    const [shView,setShView] =  useState(false);

    return (
        <div className={`filterMoSection mContents ${data.addClass||""}`}>
            <div className="filterMoTitleBox">
                {data.title ? <h1 className={`filterMoTitle ${shView ? "active" : ""}`} dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
                {data.shType ? 
                    <div className={`filterMoShBox ${shView ? "active" : ""}`}>
                        <FilterSh
                            boxAddClass={`filterMoShInput`}
                            addClass=""
                            value={data.shValue}
                            placeholder={data.shPlaceholder}
                            regexp={null}
                            func={(val)=>{data.shFunc(val)}}
                            btnFunc={()=>{data.shBtnFunc()}}
                            readOnly={false}
                            disabled={false}
                        />
                        <button type="button" onClick={()=>{setShView(!shView)}} className="btn_filterMoShView">
                            <img src={`/assets/images/basic/${shView ? "close" : "sh_icon"}.svg`}/>
                        </button>
                    </div>
                :""}
            </div>
            {data.children}
        </div>
    );
}

function FilterMoTap(data){
    return (
        <div className={`filterMoTap ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

export {FilterSection,FilterArea,FilterTapItem,FilterSh,FilterMoSection,FilterMoTap};