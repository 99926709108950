function TermsContentsBox(data){
    return (
        <div className={`terms_contentsBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function TermsTitle(data){
    return (
        <h1 className={`terms_title ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.title}}/>
    );
}

function TermsContents(data){
    return (
        <div className={`terms_contetns editerBox ql-snow ${data.addClass ? data.addClass : ""}`}>
            <div className="terms_contetnsItem ql-editor" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

export {TermsContentsBox,TermsTitle,TermsContents};