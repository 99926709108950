import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { Popup } from "component/basic/popup";
import { NotItems } from "component/basic/notItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { comFormat } from "js/function";
import { FixedSection, TableSection, TableTd, TableTextItem, TableTr } from "component/basic";
import { ChkBox } from "component/basic/formItems";
import { CartDel, NotRemainAmountText, OrderFixedItemBox, OrderFixedItemTextBox, OrderFixedSubItemBox, OrderPage, OrderProduct, OrderProductPrice, OrderSection, OrderTablePrictBox } from "component/product/order";
import CountInput from "component/product/count";

const MarketCart = (props) => {
    const navigate = useNavigate();

    const [totalCart,setTotalCart] =  useState(0);
    const [checkItems, setCheckItems] = useState([]);

    const [totalPrice,setTotalPrice] =  useState(0);
    const [totalDiscount,setTotalDiscount] =  useState(0);

    const [cartData,setCartData] =  useState([]);

    const [submitChk,setSubmitChk] =  useState(true);
    const [popupData,setPopupData] =  useState(null);

    let timeOut = "";

    const cartApi = useGet({
        url:`/cart`,
        loginType:"login"
    });
    const cartApiData = cartApi?.data?.users;

    // 체크박스 선택
    const handleSingleCheck = (checked, item) => {
        let oldData = [...checkItems];
        if(checked){
            oldData.push(item);
            setCheckItems(oldData);
       } else {
            setCheckItems(checkItems.filter((el) => el.id !== item.id));
       }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
                const itemArr = [];

                cartData?.forEach(function(item,i){
                    item.carts?.forEach(function(subItem,idx){
                        if(!(subItem?.remain_amount === 0 || subItem?.option_remain_amount === 0) && !cartData?.filter((el)=>el.id == subItem.id)[0]){
                            itemArr.push(subItem);
                        }                        
                    });
                });

                setCheckItems(itemArr);
        }
        else {
            setCheckItems([]);
        }
    }

    function countFunc(item,qty){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();
            formData.append("cart_id", item.id);
            formData.append("amount", qty);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType: "login",
                url: `/cart/amount`,
                success: (data) => {
                    setSubmitChk(true);

                    let oldData = [...cartData];
                    oldData?.forEach(function(forItem,idx){
                        if(forItem?.carts?.filter((el) => el.id == item.id)[0]){
                            oldData[idx].carts = forItem?.carts?.map((subItem) => subItem.id == item.id ? 
                                {
                                    ...subItem,
                                    amount: qty
                                } 
                            : subItem)
                        }
                    })
                    setCartData(oldData);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                }
            })
        }
    }

    function cartDel(type,id){
        if(submitChk){
            setSubmitChk(false);
            let cartidText = "?cart_ids[]=";

            if(type === "all"){
                cartidText = cartidText + "all";
            }else if(type === "single"){
                cartidText = cartidText + id;
            }else if(type === "sel"){
                checkItems.forEach(function(item,i){
                    if(i === 0){
                        cartidText = cartidText + item.id;
                    }else{
                        cartidText = cartidText + `&cart_ids[]=${item.id}`;
                    }
                });
            }

            fatchSet.FatchApi({
                type:"DELETE",
                loginType: "login",
                url: `/cart/delete${cartidText}`,
                success: (data) => {
                    setSubmitChk(true);

                    window.location.reload(true);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                }
            })
        }
    }

    useEffect(() => {
        let totalCartCount = 0;
        if(cartApiData){
            cartApiData?.forEach(function(item,i){
                totalCartCount = totalCartCount + item.carts?.length||0;

                if(cartApiData?.length - 1 === i){
                    setTotalCart(totalCartCount);
                }
            })

            setCartData(cartApiData);
        }
    }, [cartApi]);

    useEffect(() => {
        let total = 0,
            discount = 0;
        
        if(checkItems && checkItems?.length > 0){
            checkItems?.forEach(function(item,i){
                total = total + ((Number(item.price) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.amount));
                discount = discount + (Number(item.discount_price) * Number(item.amount));
                
                if(checkItems?.length - 1 === i){
                    setTotalPrice(total);
                    setTotalDiscount(discount);
                }
            })
        }else{
            setTotalPrice(0);
            setTotalDiscount(0);
        }
    }, [checkItems]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                mBtn={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={"장바구니"}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <OrderPage>
                    <OrderSection>
                        <div className="tableTopBtnBox">
                            <ChkBox
                                addClass="cartAll"
                                func={(e) => handleAllCheck(e.target.checked)}
                                checkedType={checkItems?.length === totalCart ? true : false}
                                label={`<b>전체 선택(${checkItems?.length}/${totalCart})</b>`}
                            />
                            <button type="button" className="tableTapBtn" onClick={()=>{
                                if(checkItems?.length > 0){
                                    setPopupData({
                                        addClass:null,
                                        title:"선택한 상품 삭제",
                                        text:"선택한 상품을 삭제하시겠습니까?",
                                        closeType:true,
                                        closeFunc:(e) => {setPopupData(null)},
                                        btnFunc0:(e)=>{setPopupData(null)},
                                        btn0:"취소",
                                        btn0Type:"line",
                                        btn1:"삭제",
                                        btn1Type:"",
                                        btnFunc1:()=>{setPopupData(null);cartDel("sel")}
                                    });
                                }else{
                                    window.errMsg("상품을 선택해주세요.");
                                }
                            }}>선택한 상품 삭제</button>
                        </div>
                        <TableSection
                            headType={true}
                            headAddClass="pcContents"
                            th={[
                                {
                                    style:{minWidth:"50px",width:"50px"},
                                    contents:``
                                },
                                {
                                    style:{minWidth:"calc(58% - 33.33px)",width:"calc(58% - 33.33px)"},
                                    contents:`상품 정보`
                                },
                                {
                                    style:{minWidth:"calc(18% - 33.33px)",width:"calc(18% - 33.33px)"},
                                    contents:`수량`
                                },
                                {
                                    style:{minWidth:"calc(24% - 33.33px)",width:"calc(24% - 33.33px)"},
                                    contents:`주문 금액`
                                },
                                {
                                    style:{minWidth:"50px",width:"50px"},
                                    contents:``
                                }
                            ]}
                        >
                            {cartData && cartData?.length > 0 ? 
                            <>
                            {cartData?.map((item,i)=>(
                                <Fragment key={i}>
                                <TableTextItem text={`${item.name} 님의 장바구니`}/>
                                {item?.carts?.map((subItem,idx)=>(
                                <TableTr key={idx} addClass={`chkAddTr ${subItem?.remain_amount === 0 || subItem?.option_remain_amount === 0 ? "notRemainAmount" : ""}`}>
                                    <TableTd
                                        addClass="chkTd"
                                        style={{minWidth:"50px",width:"50px"}}
                                    >
                                        {subItem?.remain_amount === 0 || subItem?.option_remain_amount === 0 ? 
                                            ""
                                        :
                                            <ChkBox
                                                key={i}
                                                addClass="notText"
                                                func={(e) => handleSingleCheck(e.target.checked,subItem)}
                                                checkedType={checkItems?.filter((el) => el.id === subItem.id)[0] ? true : false}
                                                label={""}
                                            />
                                        }
                                    </TableTd>
                                    <TableTd
                                        addClass="alignLeft"
                                        style={{minWidth:"calc(58% - 33.33px)",width:"calc(58% - 33.33px)"}}
                                    >
                                        <div className="cartProductBox">
                                            <OrderProduct
                                                image_url={subItem?.main_image_url}
                                                name={subItem?.name}
                                                options={subItem?.option_name}
                                            />
                                            {subItem?.remain_amount === 0 || subItem?.option_remain_amount === 0 ? 
                                                ""
                                            :
                                                <CartDel
                                                    addClass="mContents"
                                                    func={()=>{
                                                        setPopupData({
                                                            addClass:null,
                                                            title:"상품 삭제",
                                                            text:"상품을 삭제하시겠습니까?",
                                                            closeType:true,
                                                            closeFunc:(e) => {setPopupData(null)},
                                                            btnFunc0:(e)=>{setPopupData(null)},
                                                            btn0:"취소",
                                                            btn0Type:"line",
                                                            btn1:"삭제",
                                                            btn1Type:"",
                                                            btnFunc1:()=>{setPopupData(null);cartDel("single",subItem.id)}
                                                        });
                                                    }}
                                                    text="<img src='/assets/images/icon/del.svg'/>"
                                                />
                                            }
                                        </div>
                                    </TableTd>
                                    <TableTd
                                        addClass="pcContents"
                                        style={{minWidth:"calc(18% - 33.33px)",width:"calc(18% - 33.33px)"}}
                                    >
                                        {subItem?.remain_amount === 0 || subItem?.option_remain_amount === 0 ? 
                                            ""
                                        :
                                            <CountInput
                                                val={subItem?.amount}
                                                qty={null}
                                                optionQty={null}
                                                apiType={true}
                                                maxQty={subItem?.remain_amount}
                                                optionMaxQty={subItem?.option_remain_amount}
                                                func={(val)=>{
                                                    clearTimeout(timeOut);

                                                    timeOut = setTimeout(() => {
                                                        countFunc(subItem,val)
                                                    }, 200);
                                                }}
                                                excessFunc={()=>{}}
                                            />
                                        }
                                    </TableTd>
                                    <TableTd
                                        style={{minWidth:"calc(24% - 33.33px)",width:"calc(24% - 33.33px)"}}
                                    >
                                        <OrderTablePrictBox>
                                        {subItem?.remain_amount === 0 || subItem?.option_remain_amount === 0 ? 
                                            <NotRemainAmountText text="품절"/>
                                        :
                                            <>
                                                <OrderProductPrice
                                                    discountPrice={subItem.discount_price ? `${comFormat(((Number(subItem.price) + (subItem.option_price ? Number(subItem.option_price) : 0)) * Number(subItem.amount)) - (Number(subItem.discount_price) * Number(subItem.amount)))}원` : null}
                                                    price={`${comFormat((Number(subItem.price) + (subItem.option_price ? Number(subItem.option_price) : 0)) * Number(subItem.amount))}원`}
                                                />
                                                <CountInput
                                                    addClass="mContents flexItem"
                                                    val={subItem?.amount}
                                                    qty={null}
                                                    optionQty={null}
                                                    apiType={true}
                                                    maxQty={subItem?.remain_amount}
                                                    optionMaxQty={subItem?.option_remain_amount}
                                                    func={(val)=>{
                                                        clearTimeout(timeOut);

                                                        timeOut = setTimeout(() => {
                                                            countFunc(subItem,val)
                                                        }, 200);
                                                    }}
                                                    excessFunc={()=>{}}
                                                />
                                            </>
                                        }
                                        </OrderTablePrictBox>
                                    </TableTd>
                                    <TableTd
                                        addClass="pcContents"
                                        style={{minWidth:"50px",width:"50px"}}
                                    >
                                        {subItem?.remain_amount === 0 || subItem?.option_remain_amount === 0 ? 
                                            ""
                                        :
                                            <CartDel
                                                func={()=>{
                                                    setPopupData({
                                                        addClass:null,
                                                        title:"상품 삭제",
                                                        text:"상품을 삭제하시겠습니까?",
                                                        closeType:true,
                                                        closeFunc:(e) => {setPopupData(null)},
                                                        btnFunc0:(e)=>{setPopupData(null)},
                                                        btn0:"취소",
                                                        btn0Type:"line",
                                                        btn1:"삭제",
                                                        btn1Type:"",
                                                        btnFunc1:()=>{setPopupData(null);cartDel("single",subItem.id)}
                                                    });
                                                }}
                                                text="<img src='/assets/images/icon/del.svg'/>"
                                            />
                                        }
                                    </TableTd>
                                </TableTr>
                                ))}
                                </Fragment>
                            ))}
                            </>
                            :
                                <NotItems addClass="cart_notItems" img="/assets/images/icon/notCart.svg" text={`장바구니에 담긴 상품이 없습니다.`}/>
                            }
                        </TableSection>
                    </OrderSection>
                    <FixedSection
                        pageBox="pageSizing"
                        boxAddClass="order_fixedsection"
                        addClass="order_fixedArea"
                        contentsBox="orderPage"
                        headerChk={true}
                        footerChk={true}
                        minSize={961}
                    >
                        <OrderFixedItemBox
                            name="예상 결제금액"
                        >
                            <OrderFixedItemTextBox
                                textColorClass=""
                                name={"총 상품 금액"}
                                val={`${comFormat(totalPrice)} 원`}
                            />
                            <OrderFixedItemTextBox
                                textColorClass=""
                                name={"총 할인 금액"}
                                val={`${comFormat(totalDiscount)} 원`}
                            />
                            <OrderFixedItemTextBox
                                textColorClass=""
                                name={"배송비"}
                                val={`${comFormat(4000)} 원`}
                            />
                        </OrderFixedItemBox>
                        <OrderFixedSubItemBox>
                            <OrderFixedItemTextBox
                                textColorClass="oTextColor"
                                name={`<b>총 결제 금액</b>`}
                                val={`<b>${comFormat(totalPrice + 4000 - totalDiscount)} 원</b>`}
                            />
                        </OrderFixedSubItemBox>
                        <BtnBox
                            boxType="fixed"
                            addClass="pcBasicBtnBox row2 orderBtnBox pcContents"
                        >
                            <BtnItem
                                addClass=""
                                contents={"주문하기"}
                                disabled={false}
                                func={() => {
                                    if(checkItems?.length > 0){
                                        navigate(`/order`,{state: {type:"cart",options:checkItems}});
                                    }else{
                                        window.errMsg("상품을 선택해주세요.");
                                    }
                                }}
                            />
                            <BtnItem
                                addClass="line"
                                contents={"장바구니 비우기"}
                                disabled={false}
                                func={() => {
                                    setPopupData({
                                        addClass:null,
                                        title:"장바구니 비우기",
                                        text:"담긴 상품을 모두 비우시겠습니까?",
                                        closeType:true,
                                        closeFunc:(e) => {setPopupData(null)},
                                        btnFunc0:(e)=>{setPopupData(null)},
                                        btn0:"취소",
                                        btn0Type:"line",
                                        btn1:"비우기",
                                        btn1Type:"",
                                        btnFunc1:()=>{setPopupData(null);cartDel("all")}
                                    });
                                }}
                            />
                        </BtnBox>
                        <BtnBox
                            boxType="fixed"
                            addClass="col2 orderBtnBox mContents flexItem"
                        >
                            <BtnItem
                                addClass=""
                                contents={"주문하기"}
                                disabled={false}
                                func={() => {
                                    if(checkItems?.length > 0){
                                        navigate(`/order`,{state: {type:"cart",options:checkItems}});
                                    }else{
                                        window.errMsg("상품을 선택해주세요.");
                                    }
                                }}
                            />
                            <BtnItem
                                addClass="oColor"
                                contents={"장바구니 비우기"}
                                disabled={false}
                                func={() => {
                                    setPopupData({
                                        addClass:null,
                                        title:"장바구니 비우기",
                                        text:"담긴 상품을 모두 비우시겠습니까?",
                                        closeType:true,
                                        closeFunc:(e) => {setPopupData(null)},
                                        btnFunc0:(e)=>{setPopupData(null)},
                                        btn0:"취소",
                                        btn0Type:"line",
                                        btn1:"비우기",
                                        btn1Type:"",
                                        btnFunc1:()=>{setPopupData(null);cartDel("all")}
                                    });
                                }}
                            />
                        </BtnBox>
                    </FixedSection>
                </OrderPage>
            </ContentsSection>
            <Footer pcOnly={true}/>
            <Popup
                data={popupData}
            />
        </>
    );
};

export default MarketCart;