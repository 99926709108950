import { DotText, TableBasicTextItem, TableMinBtn, TableSection, TableTd, TableTextValItem, TableTr } from "component/basic";
import { InputItemBox } from "component/basic/formItems";
import { NotItems } from "component/basic/notItems";
import { Fragment } from "react";

function IngredientKeySection(data){

    return (
        <div className="ingredientKeySection">
            <h1 className="ingredientKey_title pcContents">재료 검색하기</h1>
            <div className="ingredientKey_textBox pcContents">
                <DotText text="고등어, 브로콜리, 올리브 등 고객님께서 선호하거나 기피하는 재료를 검색해 보세요."/>
                <DotText text="검색한 재료마다 선호/기피를 선택하시면 선택한 값에 따라 식단/레시피를 추천하거나 제외해 드려요."/>
                <DotText text="가족 프로필에 따라 별도 설정이 가능하며, 가족의 선호/기피 재료를 설정해 보세요."/>
            </div>
            <div className="ingredientKey_inputBox">
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName={null}
                    placeholder="고등어, 브로콜리, 올리브 등 키워드 입력"
                    value={data.keyVal}
                    max={null}
                    regexp={null}
                    func={(value)=>{data.inputFunc(value)}}
                />
                <button type="button" onClick={()=>{data.shFunc()}} className="btn_ingredientKey_sh">
                    <img src="/assets/images/basic/sh_icon.svg"/>
                </button>
            </div>
        </div>
    );
}

function IngredientType(data){

    return (
        <div 
            className={`ingredientType ${data.type === "선호" || data.type === "선호 재료" ? "gColor" : data.type === "기피" || data.type === "기피 재료" ? "rColor" : ""} ${data.type === "선호 재료" || data.type === "기피 재료" ? "active" : ""}`}
            onClick={()=>{
                if(data.type === "선호" || data.type === "기피"){
                    data.func()
                }
            }}
        >
            <h1 className="ingredientType_text" dangerouslySetInnerHTML={{__html:data.type}}/>
            {data.type === "선호 재료" || data.type === "기피 재료" ? 
                <button type="button" onClick={()=>{data.func()}} className="btn_ingredientType">
                    <img src={`/assets/images/icon/${data.type === "선호 재료" ? "prefer" : "avoid"}.svg`}/>
                </button>
            :""}
        </div>
    );
}

function IngredientTable(data){

    return (
        <TableSection
            headType={true}
            headAddClass="pcContents"
            th={[
                {
                    style:{minWidth:"14%",width:"14%",fontSize:"11px"},
                    contents:`재료 이름`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`1회 제공량`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`열량`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`탄수화물`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`단백질`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`지방`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`당류`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`나트륨`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`콜레스테롤`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`포화지방산`
                },
                {
                    style:{minWidth:"7.5%",width:"7.5%",fontSize:"11px"},
                    contents:`트랜스지방산`
                },
                {
                    style:{minWidth:"11%",width:"11%",fontSize:"11px"},
                    contents:`선호/기피`
                }
            ]}
        >
            {data.items && data.items?.length > 0 ? 
            <>
            {data.items?.map((item,i)=>(
                <Fragment key={i}>
                <TableTr addClass={`ingredientTr`}>
                    <TableTd
                        addClass="mFullTd"
                        style={{minWidth:"14%",width:"14%"}}
                    >
                        <TableBasicTextItem addClass="pcContents" text={item.foodNm||item.food_name||"-"}/>
                        <TableBasicTextItem addClass="mContents" text={`<b>${item.foodNm||item.food_name||"-"}</b>`}/>
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"1회 제공량"}
                            val={`${item.nutConSrtrQua||item.nutConSrtrQua == 0 ? item.nutConSrtrQua : "100"}g`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"열량"}
                            val={`${item.enerc||item.enerc == 0 ? item.enerc : "-"}g`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"탄수화물"}
                            val={`${item.chocdf||item.chocdf == 0 ? item.chocdf : "-"}g`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"단백질"}
                            val={`${item.prot||item.prot == 0 ? item.prot : "-"}g`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"지방"}
                            val={`${item.fatce||item.fatce == 0 ? item.fatce : "-"}g`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"당류"}
                            val={`${item.sugar||item.sugar == 0 ? item.sugar : "-"}g`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"나트륨"}
                            val={`${item.nat||item.nat == 0 ? item.nat : "-"}mg`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"콜레스테롤"}
                            val={`${item.chole||item.chole == 0 ? item.chole : "-"}mg`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"포화지방산"}
                            val={`${item.fasat||item.fasat == 0 ? item.fasat : "-"}g`}
                        />
                    </TableTd>
                    <TableTd
                        addClass=""
                        style={{minWidth:"7.5%",width:"7.5%"}}
                    >
                        <TableTextValItem
                            mName={true}
                            name={"트랜스지방산"}
                            val={`${item.fatrn||item.fatrn == 0 ? item.fatrn : "-"}g`}
                        />
                    </TableTd>

                    <TableTd
                        addClass=""
                        style={{minWidth:"10%",width:"10%"}}
                    >
                        <div className="ingredientType_btnBox">
                        {data.tap === "prefer" || item.is_preference == 1 ? 
                            <IngredientType
                                func={()=>{
                                    data.delFunc(item.id)
                                }}
                                type="선호 재료"
                            />
                        : data.tap === "avoid" || item.is_avoid == 1 ? 
                            <IngredientType
                                func={()=>{
                                    data.delFunc(item.id)
                                }}
                                type="기피 재료"
                            />
                        :
                        <>
                            <IngredientType
                                func={()=>{
                                    data.preferFunc(item,"prefer")
                                }}
                                type="선호"
                            />
                            <IngredientType
                                func={()=>{
                                    data.avoidFunc(item,"avoid")
                                }}
                                type="기피"
                            />
                        </>
                        }
                        </div>
                    </TableTd>
                </TableTr>
                </Fragment>
            ))}
            </>
            : <NotItems addClass="orderTable_notItems" text={data.notItemText}/>}
        </TableSection>
    );
}

export {IngredientKeySection,IngredientTable};