import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { MypageContents, MypageSection, MypageSideMenu } from "component/mypage/items";

const MyReview = (props) => {
    const navigate = useNavigate();

	useEffect(() => {
		const scriptItem = document.createElement("script");
        scriptItem.id = "vreviewScript";
        scriptItem.src = `https://script.vreview.tv/vreview.widget.js?vrid=${process.env.REACT_APP_VREVIEW_ID}`;
        document.head.appendChild(scriptItem);
        
        return () => {
            if(scriptItem){
                scriptItem.remove();
            }
        };
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={"나의 리뷰"}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MypageContents>
                    <MypageSideMenu tapActive={2}/>
                    <MypageSection addClass="reviewArea">
                        <div className="vreview-myreview-widget"></div>
                    </MypageSection>
                </MypageContents>
            </ContentsSection>
            <Footer/>
        </>
    );
};

export default MyReview;