import Main from "pages/main/Main";
import HealthSurvey from "pages/main/Survey";
import HealthSurveyDetail from "pages/main/SurveyDetail";
import MarketCart from "pages/market/Cart";
import MarketDetail from "pages/market/Detail";
import MarketList from "pages/market/List";
import Market from "pages/market/Main";
import MarketOrder from "pages/market/Order";
import FindId from "pages/member/FindId";
import FindPw from "pages/member/FindPw";
import Join from "pages/member/Join";
import Login from "pages/member/Login";
import JoinTerms from "pages/member/Terms";
import WelCome from "pages/member/WelCome";
import EditPhone from "pages/mypage/EditPhone";
import EditProfile from "pages/mypage/EditProfile";
import MyFamily from "pages/mypage/Family";
import Ingredient from "pages/mypage/Ingredient";
import MyMenu from "pages/mypage/Menu";
import MyMenuDetail from "pages/mypage/MenuDetail";
import MyOrder from "pages/mypage/Order";
import MyOrderDetail from "pages/mypage/OrderDetail";
import MyRecipe from "pages/mypage/Recipe";
import MyReview from "pages/mypage/Review";
import NutritionSurvey from "pages/nutrition/Survey";
import NutritionSurveyDetail from "pages/nutrition/SurveyDetail";
import PublicOnly from "pages/private/PublicOnly";
import RecipeDetail from "pages/recipe/Detail";
import RecipeList from "pages/recipe/List";
import Terms from "pages/terms/Terms";
import React from "react";
import { Navigate } from "react-router-dom";

//type
//login : 로그인 한정 페이지
// notLogin : 비로그인 한정 페이지
// null : 로그인,비로그인 무관 페이지

export const routes = [
    {
        exact: true,
        path: "/pageErr",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/pageErr/:err",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/",
        component: <Navigate to="/main" />,
        type : null
    },
    {
        exact: true,
        path: "/main",
        component: <Main/>,
        type : null
    },
    {
        exact: true,
        path: "/terms/:id",
        component: <Terms/>,
        type : null
    },
    
    {
        exact: true,
        path: "/welCome",
        component: <WelCome/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/member/login",
        component: <Login/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/member/terms/:type",
        component: <JoinTerms/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/member/join/:type/:marketing",
        component: <Join/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/member/find/id",
        component: <FindId/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/member/find/pw",
        component: <FindPw/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/healthSurvey",
        component: <HealthSurvey/>,
        type : "login"
    },
    {
        exact: true,
        path: "/healthSurvey/detail",
        component: <HealthSurveyDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/market",
        component: <Market/>,
        type : null
    },
    {
        exact: true,
        path: "/market/list",
        component: <MarketList/>,
        type : null
    },
    {
        exact: true,
        path: "/market/detail/:id",
        component: <MarketDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/order/cart",
        component: <MarketCart/>,
        type : "login"
    },
    {
        exact: true,
        path: "/order",
        component: <MarketOrder/>,
        type : "login"
    },
    {
        exact: true,
        path: "/recipe",
        component: <RecipeList/>,
        type : null
    },
    {
        exact: true,
        path: "/recipe/detail/:id",
        component: <RecipeDetail/>,
        type : null
    },
    {
        exact: true,
        path: "/nutrition/survey",
        component: <NutritionSurvey/>,
        type : "login"
    },
    {
        exact: true,
        path: "/nutrition/detail/:id",
        component: <NutritionSurveyDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/order",
        component: <MyOrder/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/order/detail/:id/:type",
        component: <MyOrderDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/review",
        component: <MyReview/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/recipe",
        component: <MyRecipe/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/editProfile",
        component: <EditProfile/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/editPhone",
        component: <EditPhone/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/family",
        component: <MyFamily/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/ingredient",
        component: <Ingredient/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/menu",
        component: <MyMenu/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/menu/detail/:id",
        component: <MyMenuDetail/>,
        type : "login"
    },
];