import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { Popup, ToastPopup } from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { comFormat, moveScroll } from "js/function";
import { PointFixedTap, Tap } from "component/basic/tap";
import { DetailBottomInfoSection, DetailCategoriesSection, DetailCopyText, DetailMenusSection, DetailSection, OptionSection, ProductDetailInfo } from "component/product/detail";
import { SurveyDetailTitleBox } from "component/survey/items";

const MarketDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const copyRef = useRef(null);

    const [toastPopupData,setToastPopupData] =  useState(null);

    const [tap,setTap] =  useState(null);
    const [bottomInfo,setBottomInfo] =  useState("");

    const [like,setLike] =  useState(0);
    const [options,setOptions] =  useState([]);

    const [submitChk,setSubmitChk] =  useState(true);
    
    const [discount_totalPrice,setDiscount_totalPrice] =  useState(null);
    const [totalPrice,setTotalPrice] =  useState(0);

    const [popupData,setPopupData] =  useState(null);

    const [periods,setPeriods] =  useState(null);
    const [weekCounts,setWeekCounts] =  useState(null);
    const [dayCounts,setDayCounts] =  useState(null);

    const [periodsData,setPeriodsData] =  useState(null);
    const [weekCountsData,setWeekCountsData] =  useState(null);
    const [dayCountsData,setDayCountsData] =  useState(null);

    const [subscribeOptionChk,setSubscribeOptionChk] =  useState(false);

    const detailApi = useGet({
        url:`/product/detail?product_id=${id}`,
        loginType:"login"
    });
    const detailApiData = detailApi?.data;
    const productData = detailApiData?.product;
    const product_options = detailApiData?.product_options;
    const product_menus = detailApiData?.product_menus;
    const product_ingredient_categories = detailApiData?.product_ingredient_categories;
    const product_periods = detailApiData?.product_periods;
    const product_week_counts = detailApiData?.product_week_counts;
    const product_day_counts = detailApiData?.product_day_counts;
    const nutrition_config = detailApiData?.nutrition_config;

    function sharingFunc(){
        copyRef?.current?.select();
        document.execCommand('copy');
        copyRef?.current?.blur();
        window.errMsg("클립보드에 복사되었습니다.");
    }

    function addOrder(){
        if(options?.length > 0 || (productData?.type === "basic" && product_options?.length <= 0)){
            if(product_options?.length <= 0 && productData?.type === "basic"){
                let oldData = [];
                oldData.push({
                    id:"notOption",
                    name:null,
                    productId:id,
                    productName:productData?.name,
                    productImg:productData?.main_image_url,
                    add_price:Number(productData?.price),
                    discount_price:productData?.discount_price ? (Number(productData?.price)) - Number(productData?.discount_price) : null,
                    discount_basic_price:productData?.discount_price||null,
                    remain_amount:null,
                    qty:1
                });
                navigate(`/order`,{state: {type:productData?.type,options:oldData}});
            }else{
                navigate(`/order`,{state: {type:productData?.type,options:options}});
            }
        }else{
            window.errMsg("필수 옵션을 선택해주세요.");
        }
    }

    function addCart(){
        if(options?.length > 0 || product_options?.length <= 0){
            if(submitChk){
                setSubmitChk(false);
    
                let formData = new FormData();
                
                if(sessionStorage.getItem("familyId")){
                    formData.append("user_family_id", sessionStorage.getItem("familyId"));
                }
                formData.append("product_id", id);

                options?.forEach(function(item,i){
                    formData.append(`option_id[${i}]`, item.id);
                    formData.append(`option_amount[${i}]`, item.qty);
                })
    
                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    loginType:"login",
                    url: "/product/cart",
                    success: (data) => {
                        setPopupData({
                            addClass:null,
                            title:"장바구니 담기 완료",
                            text:"선택하신 상품을 장바구니에 담았습니다.<br/>지금 바로 장바구니로 이동하시겠어요?",
                            closeType:true,
                            closeFunc:(e) => {setPopupData(null)},
                            btnFunc0:(e)=>{setPopupData(null)},
                            btn0:"쇼핑 계속하기",
                            btn0Type:"line",
                            btn1:"장바구니 이동",
                            btn1Type:"",
                            btnFunc1:()=>{setPopupData(null);navigate("/order/cart")}
                        });
                    },
                    err: (data) => {
                        window.errPopupOpen(data);
                        setSubmitChk(true);
                    }
                })
            }
        }else{
            window.errMsg("필수 옵션을 선택해주세요.");
        }
    }

    function totalPriceSetting(){
        let discount = null,
            total = 0;
        let discountOption = 0,
            discountChk = false;

        if(options?.length > 0){
            options?.forEach(function(item,i){
                if(item.discount_price){
                    discountChk = true;
                }

                discountOption = discountOption + (Number(item.discount_price) * Number(item.qty));
                total = total + (Number(item.add_price) * Number(item.qty));

                if(options?.length - 1 === i){
                    if(discountChk){
                        discount = discountOption;
                    }
                    
                    setTotalPrice(total);
                    setDiscount_totalPrice(discount);
                }
            })
        }else{
            setTotalPrice(total);
            setDiscount_totalPrice(discount);
        }
    }

    function optionSel(name,optionId,price,amount){
        let oldData = [...options];
        if(oldData?.filter((el) => el?.id === optionId)[0]){
            window.errMsg("이미 선택된 옵션입니다.");
        }else{
            oldData.push({
                id:optionId,
                name:name,
                productId:id,
                productName:productData?.name,
                productImg:productData?.main_image_url,
                add_price:Number(productData?.price) + Number(price),
                discount_price:productData?.discount_price ? (Number(productData?.price) + Number(price)) - Number(productData?.discount_price) : null,
                discount_basic_price:productData?.discount_price||null,
                remain_amount:amount,
                qty:1
            });
            setOptions(oldData);
        }
    }

    function optionDel(id,weekId,dayId){
        let oldData = [...options];
        if(productData?.type === "basic"){
            oldData = oldData?.filter((el) => el?.id !== id);
        }else{
            oldData = oldData?.filter((el) => 
                el?.periods?.id !== id
                && el?.weekCounts?.id !== weekId
                && el?.dayCounts?.id !== dayId
            );
        }
        setOptions(oldData);
    }

    function optionCount(item,val){
        let oldData = [...options];
        if(productData?.type === "basic"){
            if(oldData?.filter((el) => el.id === item.id)[0]){
                oldData = oldData?.map((subItem) => subItem.id === item.id ? 
                    {
                        ...subItem,
                        qty: val
                    } 
                : subItem)
            }
        }else{
            if(oldData?.filter((el) => 
                el?.periods?.id === item?.periods?.id 
                && el?.weekCounts?.id === item?.weekCounts?.id
                && el?.dayCounts?.id === item?.dayCounts?.id
            )[0]){
                oldData = oldData?.map((subItem) => 
                    subItem?.periods?.id === item?.periods?.id 
                    && subItem?.weekCounts?.id === item?.weekCounts?.id
                    && subItem?.dayCounts?.id === item?.dayCounts?.id ? 
                    {
                        ...subItem,
                        qty: val
                    } 
                : subItem)
            }
        }
        setOptions(oldData);
    }

    function tapScrollEv(){
        let productDetail_detailSection = document.querySelectorAll(".productDetail_detailSection")[0]?.offsetTop,
            productDetail_menusSection = document.querySelectorAll(".productDetail_menusSection")[0]?.offsetTop,
            productDetail_categoriesSection = document.querySelectorAll(".productDetail_categoriesSection")[0]?.offsetTop,
            productDetail_reviewSection = document.querySelectorAll(".productDetail_reviewSection")[0]?.offsetTop,
            scrollTop = document.documentElement.scrollTop,
            headerSize = Number(document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0) + 100

        setTap(null);

        if(scrollTop + headerSize >= productDetail_reviewSection){
            setTap("productDetail_reviewSection");
        }else if(scrollTop + headerSize >= productDetail_categoriesSection){
            setTap("productDetail_categoriesSection");
        }else if(scrollTop + headerSize >= productDetail_menusSection){
            setTap("productDetail_menusSection");
        }else if(scrollTop + headerSize >= productDetail_detailSection){
            setTap("productDetail_detailSection");
            setBottomInfo("active");
        }else{
            setTap(null);
            setBottomInfo("");
        }

        if(window.innerWidth >= 961){
            setToastPopupData(null)
        }
    }

    useEffect(() => {
        if(periods && weekCounts && dayCounts){
            let oldData = [...options];

            if(
                oldData?.filter((el) => 
                    el?.periods?.id === periods?.id
                    && el?.weekCounts?.id === weekCounts?.id
                    && el?.dayCounts?.id === dayCounts?.id
                )[0]
            ){
                window.errMsg("이미 선택된 옵션입니다.");
            }else{
                oldData.push({
                    productId:id,
                    productName:productData?.name,
                    productImg:productData?.main_image_url,
                    periods:{
                        id:periods?.id,
                        value:periods?.value,
                        price:periods?.price,
                        name:periods?.name
                    },
                    weekCounts:{
                        id:weekCounts?.id,
                        value:weekCounts?.value,
                        price:weekCounts?.price,
                        name:weekCounts?.name
                    },
                    dayCounts:{
                        id:dayCounts?.id,
                        value:dayCounts?.value,
                        price:dayCounts?.price,
                        name:dayCounts?.name
                    },
                    add_price:Number(productData?.price) + (Number(periods?.price) + Number(periods?.value)) + (Number(weekCounts?.price) * Number(weekCounts?.value)) + (Number(dayCounts?.price) * Number(dayCounts?.value)),
                    discount_price: productData?.discount_price ? 
                    (Number(productData?.price) + (Number(periods?.price) + Number(periods?.value)) + (Number(weekCounts?.price) * Number(weekCounts?.value)) + (Number(dayCounts?.price) * Number(dayCounts?.value))) - Number(productData?.discount_price)
                    : null,
                    discount_basic_price: productData?.discount_price,
                    qty:1
                });
                setOptions(oldData);
            }

            setPeriods(null);
            setWeekCounts(null);
            setDayCounts(null);
        }
    }, [periods,weekCounts,dayCounts]);

    useEffect(() => {
        if(productData){
            setLike(productData?.my_like ? productData?.my_like : 0);
            setOptions([]);
            totalPriceSetting();
        }

        if(product_periods){
            let oldData = [...product_periods];

            oldData = oldData?.map((item) => {
                return {
                    ...item,
                    name : `${item.period_value}주 + 영양상담 1회 이용권`
                };
            });

            setPeriodsData(oldData);
        }

        if(product_week_counts){
            let oldData = [...product_week_counts];

            oldData = oldData?.map((item) => {
                return {
                    ...item,
                    name : `1주 당 ${item.count}개 식단`
                };
            });

            setWeekCountsData(oldData);
        }

        if(product_day_counts){
            let oldData = [...product_day_counts];

            oldData = oldData?.map((item) => {
                return {
                    ...item,
                    name : `1일 ${item.count}식`
                };
            });

            setDayCountsData(oldData);
        }
    }, [detailApi]);

    useEffect(() => {
        totalPriceSetting();

        if(options?.length <= 0){
            setSubscribeOptionChk(false);
        }else{
            setSubscribeOptionChk(true);
        }
    }, [options]);

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", tapScrollEv);
            window.addEventListener("resize", tapScrollEv);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", tapScrollEv);
            window.removeEventListener("resize", tapScrollEv);
        };
    }, []);

    useEffect(() => {
        const scriptItem = document.createElement("script");
        scriptItem.id = "vreviewScript";
        scriptItem.src = `https://script.vreview.tv/vreview.widget.js?vrid=${process.env.REACT_APP_VREVIEW_ID}`;
        document.head.appendChild(scriptItem);
        
        return () => {
            if(scriptItem){
                scriptItem.remove();
            }
        };
    }, []);
    
    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                mBtn={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={null}
                    rightBtnsArr={[
                        {
                            type:"img",
                            func:()=>{sharingFunc()},
                            addClass:"",
                            contents:"/assets/images/icon/sharing.svg"
                        }
                    ]}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <ProductDetailInfo
                    addClass=""
                    detailType="market"
                    id={productData?.id}
                    productType={productData?.type}
                    image_url={productData?.main_image_url}
                    category_name={productData?.category_name}
                    likeFunc={null}
                    sharingFunc={()=>{sharingFunc()}}
                    name={productData?.name}
                    summary={productData?.summary}
                    price={productData?.price}
                    discount_price={productData?.discount_price}

                    like={like}

                    sale_unit={productData?.sale_unit}
                    shelf_life={productData?.shelf_life}
                    information={productData?.information}
                    payment_information={productData?.payment_information}
                    delivery_type={productData?.delivery_type}
                    packaging_type={productData?.packaging_type}
                    delivery_information={productData?.delivery_information}
                    
                    options={product_options}
                    optionSelFunc={(name,id,price,amount)=>{
                        optionSel(name,id,price,amount)
                    }}
                    optionDel={(id,weekId,dayId)=>{
                        optionDel(id,weekId,dayId);
                    }}
                    countFunc={(item,val)=>{
                        optionCount(item,val)
                    }}
                    
                    optionArr={options}
                    
                    discount_totalPrice={discount_totalPrice}
                    totalPrice={totalPrice}
                    
                    addOrder={()=>{addOrder()}}
                    addCart={()=>{addCart()}}

                    product_periods={periodsData}
                    product_week_counts={weekCountsData}
                    product_day_counts={dayCountsData}

                    periodsVal={periods}
                    weekCountsVal={weekCounts}
                    dayCountsVal={dayCounts}

                    subscribeOptionChk={subscribeOptionChk}

                    periodsFunc={(name,id,price,value)=>{
                        setPeriods({
                            id:id,
                            value:value,
                            price:price,
                            name:`${value}주`
                        })
                    }}
                    weekCountsFunc={(name,id,price,value)=>{
                        setWeekCounts({
                            id:id,
                            value:value,
                            price:price,
                            name:name
                        })
                    }}
                    dayCountsFunc={(name,id,price,value)=>{
                        setDayCounts({
                            id:id,
                            value:value,
                            price:price,
                            name:name
                        })
                    }}
                />
                <div className="detailTapBox">
                    <PointFixedTap
                        data={[
                            {
                                id:`productDetail_detailSection`,
                                name:"상세 정보"
                            },
                            {
                                id:`productDetail_menusSection`,
                                name:"영양 성분"
                            },
                            {
                                id:`productDetail_categoriesSection`,
                                name:"재료 정보"
                            },
                            {
                                id:`productDetail_reviewSection`,
                                name:`상품 리뷰 (${productData?.review_count ? comFormat(productData?.review_count) : 0})`
                            } 
                        ]}
                        func={(name,id)=>{moveScroll(`.${id}`,Number(document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0) + 100)}}
                        nameKey={"name"}
                        valKey={"id"}
                        val={tap}
                        headerChk={true}
                    />
                </div>
                <DetailSection
                    addClass="productDetail_detailSection"
                >
                    <EditerBox addClass="prodeuctDetail_detailText" data={productData?.contents}/>
                </DetailSection>
                <DetailSection
                    addClass="productDetail_menusSection"
                >
                    {product_menus?.map((item,i)=>(
                        <DetailMenusSection data={item} key={i} nutrition_config={nutrition_config}/>
                    ))}
                </DetailSection>
                <DetailSection
                    addClass="productDetail_categoriesSection"
                >
                    <SurveyDetailTitleBox
                        title={`식단 내 포함 재료`}
                        subTitle={`포함되는 모든 재료들을 확인해 보세요.`}
                    />
                    <DetailCategoriesSection
                        addClass="row2"
                        data={product_ingredient_categories}
                    />
                </DetailSection>
                <DetailSection
                    addClass="productDetail_reviewSection"
                >
                    <div className="vreview-board-widget"></div>
                </DetailSection>
            </ContentsSection>
            <DetailCopyText inputRef={copyRef}/>
            <DetailBottomInfoSection
                addClass={`pcContents ${bottomInfo}`}
                name={productData?.name}
                discount_price={productData?.discount_price}
                price={productData?.price}
            />
            <ToastPopup
                data={toastPopupData}
            >
                <OptionSection
                    productType={productData?.type}
                    options={product_options}
                    optionSelFunc={(name,id,price,amount)=>{
                        optionSel(name,id,price,amount)
                    }}
                    optionArr={options}
                    optionDel={(id,weekId,dayId)=>{
                        optionDel(id,weekId,dayId);
                    }}
                    countFunc={(item,val)=>{
                        optionCount(item,val)
                    }}
                    detailType="market"
                    name={productData?.name}
                    title={productData?.title}

                    product_periods={periodsData}
                    product_week_counts={weekCountsData}
                    product_day_counts={dayCountsData}

                    periodsVal={periods}
                    weekCountsVal={weekCounts}
                    dayCountsVal={dayCounts}

                    subscribeOptionChk={subscribeOptionChk}

                    periodsFunc={(name,id,price,value)=>{
                        setPeriods({
                            id:id,
                            value:value,
                            price:price,
                            name:`${value}주`
                        })
                    }}
                    weekCountsFunc={(name,id,price,value)=>{
                        setWeekCounts({
                            id:id,
                            value:value,
                            price:price,
                            name:name
                        })
                    }}
                    dayCountsFunc={(name,id,price,value)=>{
                        setDayCounts({
                            id:id,
                            value:value,
                            price:price,
                            name:name
                        })
                    }}

                    discount_totalPrice={discount_totalPrice}
                    totalPrice={totalPrice}
                />
            </ToastPopup>
            <BtnBox
                boxType="fixed"
                addClass={`mContents flexItem ${productData?.type === "basic" && (toastPopupData || !(product_options && product_options?.length > 0)) ? "col2" : ""} indexUp`}
            >
                {toastPopupData || !((productData?.type === "basic" && product_options && product_options?.length > 0) || productData?.type === "subscribe") ? 
                    <>
                        <BtnItem
                            addClass=""
                            contents={"바로 구매하기"}
                            disabled={false}
                            func={() => {addOrder()}}
                        />
                        {productData?.type === "basic" ? 
                        <BtnItem
                            addClass="oColor"
                            contents={"장바구니 담기"}
                            disabled={false}
                            func={() => {addCart()}}
                        />
                        :""
                        }
                    </>
                    :
                    <BtnItem
                        addClass=""
                        contents={"구매하기"}
                        disabled={false}
                        func={() => {
                            setToastPopupData({
                                addClass:"mContents",
                                title:"옵션 선택",
                                closeType:true,
                                closeFuncChk:true,
                                closeFunc:(e) => {setToastPopupData(null)},
                                btnFunc0:(e)=>{},
                                btn0:null,
                                btn0Type:"",
                                btn1:null,
                                btn1Type:"",
                                btnFunc1:()=>{},
                                textBtn:null,
                                textBtnFunc:()=>{}
                            });
                        }}
                    />
                }
            </BtnBox>
            <Footer pcOnly={true}/>
            <Popup
                data={popupData}
            />
        </>
    );
};

export default MarketDetail;