import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { ageSet, bmiChk, getDateGap, setDateTime, typeChk, typeTextChk, userWeightChk } from "js/function";
import { SurveyDetailChart, SurveyDetailNutrition, SurveyDetailSection, SurveyDetailTextBox, SurveyDetailTitleBox, SurveyDetailUserInfo } from "component/survey/items";
import { LoadingBox, Popup } from "component/basic/popup";
import { DataBtn } from "component/basic";
import { ProductItem, ProductListBoxMin, SubProductList, SubProductListSlide } from "component/product/items";
import { NotItems } from "component/basic/notItems";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

const HealthSurveyDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(true);

    const [type,setType] =  useState("weight");
    const [alarm,setAlarm] =  useState(null);
    const [popupData,setPopupData] =  useState(null);

    const [chartColor,setChartColor] =  useState("#000");
    const [chartData,setChartData] =  useState({
        labels: [],
        datasets: [
            {
                label: "",
                data: [],
                backgroundColor: chartColor,
                borderColor: chartColor
            }
        ]
    });

    const detailApi = useGet({
        url:`/health/data/result?user_family_id=${sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : ""}`,
        loginType:"login"
    });
    const detailApiData = detailApi?.data;
    const health_data = detailApiData?.health_data;
    const nutrition_config = detailApiData?.nutrition_config;
    const use_health_data_alarm = detailApiData?.use_health_data_alarm;
    const customize_products = detailApiData?.customize_products;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        annotationPlugin
    );

    const [options,setOptions] =  useState({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {display: false},
          title: {display: false},
          annotation: {
            annotations: {}
          }
        }
    });

    const chartApi = useGet({
        url:detailApiData ? `/health/data/result/detail?health_data_id=${health_data?.id}&type=${type}` : null,
        loginType:"login"
    });
    const chartApiData = detailApiData ? chartApi?.data : null;

    function alarmCh(){
        let formData = new FormData();
        formData.append("use_alarm", alarm === 1 ? 0 : 1);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: "/health/data/alarm",
            success: (data) => {
                window.errPopupOpen({
                    alert: "건강 정보 업데이트 알림",
                    data: alarm === 1 ? "건강 정보 업데이트 알림 설정 취소가 완료되었습니다." : "건강 정보 업데이트 알림 설정이 완료되었습니다.",
                });
                setAlarm(alarm === 1 ? 0 : 1);
            },
            err: (data) => {
                window.errPopupOpen(data);
            }
        })
    }

    function reSurvey(){
        let nowDate =  new Date();

        if(getDateGap(`${nowDate.getFullYear()}-${nowDate.getMonth() + 1}-${nowDate.getDate()}`,health_data?.created_at,"day",false) > 30){
            navigate(`/healthSurvey`)
        }else{
            window.errPopupOpen({
                alert: "건강 데이터 등록 안내",
                data: "최근 건강 데이터를 이미 등록하셨습니다.<br/>마지막 등록 시점부터 30일 뒤에 등록해 주세요.",
            });
        }
    }

    useEffect(() => {
        //차트 데이터
        if(chartApiData && chartApiData?.health_datas){
            let chartDataArr = [];
            let labels = [];
            let colors = [];

            chartApiData?.health_datas?.forEach(function(item,i){
                chartDataArr?.push(item.value||0);
                labels?.push(item.created_at ? setDateTime(item.created_at,"yymmdd","hymd") : "-");
                colors?.push(item.status ? typeChk(item.status) : chartColor);

                if(chartApiData?.health_datas?.length - 1 === i){
                    let chartDataSet = {
                        labels,
                        datasets: [
                            {
                                label: typeTextChk(type),
                                data: chartDataArr,
                                backgroundColor: colors,
                                borderColor: chartColor
                            }
                        ]
                    }

                    setChartData(chartDataSet);
                }
            })
        }else{
            setChartData({
                labels: [],
                datasets: [
                    {
                        label: "-",
                        data: [],
                        backgroundColor: chartColor,
                        borderColor: chartColor
                    }
                ]
            });
        }

        //차트 옵션
        if(chartApiData){
            if(type === "weight"){
                let setData = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                    legend: {display: false},
                    title: {display: false},
                    annotation: {
                        annotations: {}
                    }
                    },
                    scales: {
                        y: {}
                    }
                };
                setOptions(setData);
            }else if(type === "bmi"){
                let setData = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                    legend: {display: false},
                    title: {display: false},
                    annotation: {
                        annotations: {
                        box1: {
                            type: "box",
                            yMin: 18.5,
                            yMax: 23,
                            backgroundColor: 'rgba(255, 81, 0, 0.05)',
                            borderColor: 'rgba(255, 81, 0, 0.3)'
                        }
                        }
                    }
                    },
                    scales: {
                        y: {
                            max: 60,
                            min: 0,
                            ticks: {
                                stepSize: 10
                            }
                        }
                    }
                };
                setOptions(setData);
            }else{
                let disease_ranges = chartApiData?.disease_ranges;
                let proper = disease_ranges && disease_ranges?.filter((el) => el.type == "적정")[0] ? disease_ranges?.filter((el) => el.type == "적정")[0] : {min_value:0,max_value:0};
                let dangers = disease_ranges && disease_ranges?.filter((el) => el.type == "매우 위험")[0] ? disease_ranges?.filter((el) => el.type == "매우 위험")[0] : {min_value:0,max_value:0};

                let setData = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                    legend: {display: false},
                    title: {display: false},
                    annotation: {
                        annotations: {
                        box1: {
                            type: "box",
                            yMin: proper?.min_value,
                            yMax: proper?.max_value,
                            backgroundColor: 'rgba(255, 81, 0, 0.05)',
                            borderColor: 'rgba(255, 81, 0, 0.3)'
                        }
                        }
                    }
                    },
                    scales: {
                        y: {
                            max: dangers?.max_value,
                            min: proper?.min_value,
                            ticks: {
                                stepSize: Math.ceil((dangers?.max_value - proper?.min_value) / 6)
                            }
                        }
                    }
                };
                setOptions(setData);
            }
        }else{
            let setData = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                legend: {display: false},
                title: {display: false},
                annotation: {
                    annotations: {}
                }
                },
                scales: {
                    y: {}
                }
            };
            setOptions(setData);
        }
    }, [chartApi]);

    useEffect(() => {
        if(detailApiData){
            setAlarm(use_health_data_alarm);

            if(health_data && health_data?.bmi){
                setChartColor(typeChk(bmiChk(health_data?.bmi),true));
            }

            setLoadingBoxOpen(false);
        }

        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 10000);
    }, [detailApi]);

    useEffect(() => {
        if(document.querySelector("#vreviewScript")){
            document.querySelector("#vreviewScript").remove();
        }

        const scriptItem = document.createElement("script");
        scriptItem.id = "vreviewScript";
        scriptItem.src = `https://script.vreview.tv/vreview.widget.js?vrid=${process.env.REACT_APP_VREVIEW_ID}`;
        document.head.appendChild(scriptItem);
        
        return () => {
            if(scriptItem){
                scriptItem.remove();
            }
        };
    }, [detailApi]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <SurveyDetailSection addClass="surveyDetail_flexSection">
                    <SurveyDetailTitleBox
                        title={`<span class='oTextColor'>${health_data?.name||"고객"}</span> 님의 건강 데이터`}
                        subTitle={`마지막 업데이트 ${health_data?.created_at||"-"}`}
                    />
                    <SurveyDetailUserInfo
                        data={[
                            {
                                name:"키",
                                val:`${health_data?.height||"-"} cm`
                            },
                            {
                                name:"체중",
                                val:`${health_data?.weight||"-"} kg`
                            },
                            {
                                name:"나이",
                                val:`${ageSet(health_data?.created_at,health_data?.birth)||"-"} 세`
                            }
                        ]}
                    />
                </SurveyDetailSection>
                <SurveyDetailSection addClass="">
                    <SurveyDetailTitleBox
                        title={`나의 건강 현황`}
                        subTitle={`새로운 건강 데이터가 업데이트 될 때마다 어떤 개선사항이 있었는지 알려드려요.`}
                    />
                    <div className="surveyDetailChartBox">
                        <SurveyDetailChart
                            type={health_data?.bmi ? bmiChk(health_data?.bmi) : null}
                            val={health_data?.weight}
                            unit="kg"
                            name="체중"
                            func={(id,color)=>{setType(id);setChartColor(color)}}
                            selVal={type}
                            id={"weight"}
                        />
                        <SurveyDetailChart
                            type={health_data?.bmi ? userWeightChk(health_data?.bmi) : null}
                            val={health_data?.bmi}
                            unit={null}
                            name="BMI"
                            func={(id,color)=>{setType(id);setChartColor(color)}}
                            selVal={type}
                            id={"bmi"}
                        />
                        {health_data?.disease === "신장" ? 
                        <>
                            <SurveyDetailChart
                                type={health_data?.cr_status||null}
                                val={health_data?.cr_value||"-"}
                                unit="mb/dL"
                                name="Cr"
                                func={(id,color)=>{setType(id);setChartColor(color)}}
                                selVal={type}
                                id={"cr_value"}
                            />
                            <SurveyDetailChart
                                type={health_data?.gfr_status||null}
                                val={health_data?.gfr_value||"-"}
                                unit="%"
                                name="사구체여과율"
                                func={(id,color)=>{setType(id);setChartColor(color)}}
                                selVal={type}
                                id={"gfr_value"}
                            />
                        </>
                        : health_data?.disease === "당뇨" ?
                        <>
                            <SurveyDetailChart
                                type={health_data?.fasting_blood_sugar_status||null}
                                val={health_data?.fasting_blood_sugar||"-"}
                                unit="mg/dL"
                                name="공복 혈당"
                                func={(id,color)=>{setType(id);setChartColor(color)}}
                                selVal={type}
                                id={"fasting_blood_sugar"}
                            />
                            <SurveyDetailChart
                                type={health_data?.glycated_hemoglobin_status||null}
                                val={health_data?.glycated_hemoglobin||"-"}
                                unit="%"
                                name="당화혈색소"
                                func={(id,color)=>{setType(id);setChartColor(color)}}
                                selVal={type}
                                id={"glycated_hemoglobin"}
                            />
                        </>
                        : health_data?.disease === "암" ?
                        <>
                            <SurveyDetailChart
                                type={health_data?.albumin_status||"-"}
                                val={health_data?.albumin_value||"-"}
                                unit="g/dL"
                                name="알부민"
                                func={(id,color)=>{setType(id);setChartColor(color)}}
                                selVal={type}
                                id={"albumin_value"}
                            />
                            <SurveyDetailChart
                                type={health_data?.hb_status||"-"}
                                val={health_data?.hb_value||"-"}
                                unit="g/dL"
                                name="헤모글로빈(Hb)"
                                func={(id,color)=>{setType(id);setChartColor(color)}}
                                selVal={type}
                                id={"hb_value"}
                            />
                        </>
                        : ""}
                    </div>
                    {chartData?.datasets[0] && chartData?.datasets[0]?.data && chartData?.datasets[0]?.data?.length > 0 ? 
                        <div className="surveyDetail_lineChartArea">
                            <div className="surveyDetail_lineChartBox">
                                <Line options={options} data={chartData}/>
                            </div>
                        </div>
                    :""}
                    {chartApiData?.feedback_title || chartApiData?.feedback_contents ? 
                    <SurveyDetailTextBox
                        title={chartApiData?.feedback_title}
                        text={chartApiData?.feedback_contents}
                    />
                    : ""}
                </SurveyDetailSection>
                <SurveyDetailSection addClass="">
                    <SurveyDetailTitleBox
                        title={`하루 영양구성`}
                        subTitle={`고객님의 건강정보를 분석한 결과, 다음과 같은 영양구성을 제안드려요.`}
                    />
                    <SurveyDetailNutrition
                        data={[
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"kcal",
                                val:nutrition_config?.calorie,
                                name:"칼로리"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"mg",
                                val:nutrition_config?.natrium,
                                name:"나트륨"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:nutrition_config?.carbohydrate,
                                name:"탄수화물"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:nutrition_config?.sugar,
                                name:"당류"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:nutrition_config?.fat,
                                name:"지방"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:nutrition_config?.saturated_fat,
                                name:"포화지방"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:nutrition_config?.protein,
                                name:"단백질"
                            }
                        ]}
                    />
                    <SurveyDetailTextBox
                        title={`<span class='oTextColor'>건강 정보</span>는 정기적으로 업데이트가 필요해요.`}
                        text={`고객님의 건강 정보는 지속적으로 변경되기 때문에 적합한 식단을 제안하기 위해서는 지속 업데이트가 필요합니다.<br/>매 월 2회 이상 정기적으로 업데이트를 하시면 적합한 영양구성과 건강 현황에 대해 업데이트 드릴게요.`}
                    >
                        <div className="surveyDetailText_btnBox">
                            <DataBtn addClass="" func={()=>{reSurvey()}}>다시 건강진단 하기</DataBtn>
                            <DataBtn addClass="" func={()=>{
                                setPopupData({
                                    addClass:null,
                                    title:alarm === 0 ? "건강 정보 업데이트 알림" : "건강 정보 업데이트 알림 취소",
                                    text:alarm === 0 ? "30일 단위로 건강 데이터를 등록하실 수 있도록<br/>알림을 보내드릴게요" : "건강 데이터 알림을 취소하시겠어요?<br/>취소할 경우 알림이 수신되지 않습니다.",
                                    closeType:true,
                                    closeFunc:(e) => {},
                                    btnFunc0:(e)=>{setPopupData(null)},
                                    btn0:"취소",
                                    btn0Type:"line",
                                    btn1:alarm === 0 ? "알림 받기" : "알림 끄기",
                                    btn1Type:"",
                                    btnFunc1:()=>{alarmCh();setPopupData(null)}
                                });
                            }}>
                                {alarm === 1 ? "건강 정보 업데이트 알림 끄기" : "건강 정보 업데이트 알림 받기"}
                            </DataBtn>
                        </div>
                    </SurveyDetailTextBox>
                </SurveyDetailSection>
                <SurveyDetailSection addClass="">
                    <SubProductList
                        addClass={customize_products && customize_products?.length > 0 ? "" : "subProductList_notItem"}
                        title="나의 건강상태<br/><span class='oTextColor'>맞춤 레시피</span>"
                        text={`지금 ${health_data?.name||"고객"}님의 건강 상태에 적합한 식단을 골라봤어요.`}
                        allBtn={true}
                        func={()=>{navigate("/recipe")}}
                        allBtnText={null}
                        btnAddClass=""
                        itemBoxAddClass="basicList"
                    >
                        {customize_products && customize_products?.length > 0 ? 
                            <ProductListBoxMin addClass="pcContents">
                                {customize_products && customize_products?.map((item, i) => (
                                    <ProductItem
                                        key={i}
                                        type="market"
                                        id={item.id}
                                        image_url={item.main_image_url}
                                        product_name={item.name}
                                        summary={item.summary}
                                        price={item.price}
                                        discount_price={item.discount_price}
                                        review={true}
                                    />
                                ))}
                            </ProductListBoxMin>
                        :
                            <NotItems text={`조건에 맞는 상품 목록이 없습니다.`}/>
                        }
                        <SubProductListSlide addClass="mContents" data={customize_products} type="market"/>
                    </SubProductList> 
                </SurveyDetailSection>
            </ContentsSection>
            <Footer/>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </>
    );
};

export default HealthSurveyDetail;