import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, InputContents } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { LoginSubLink } from "component/member/items";
import { InputItemBox } from "component/basic/formItems";
import { BtnItem } from "component/basic/btns";

const Login = (props) => {
    const navigate = useNavigate();

    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");

    const [btnChk,setBtnChk] =  useState(true);

    function loginStart(){
        let formData = new FormData();
        formData.append("login_id", id);
        formData.append("password", pw);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/login",
            success: (data) => {
                localStorage.setItem("token",data.data.token.access_token);
                localStorage.setItem("refresh_token",data.data.token.refresh_token);

                localStorage.setItem("carSize",data.data.carSize);

                window.logIn();

                setTimeout(() => {                    
                    if(sessionStorage.getItem("setUrl")){
                        let reUrl = sessionStorage.getItem("setUrl");
                        sessionStorage.removeItem("setUrl");
                        navigate(reUrl);
                    }else{
                        navigate(`/`);
                    }
                }, 100);
            },
            err: (data) => {
                window.errPopupOpen(data);
            }
        })
    }

    useEffect(() => {
        let idType = /(^(?=.*[a-zA-Z])(?=.*[0-9]).{4,20}$)|(^(?=.*[a-zA-Z]).{4,20}$)/;
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        // if(pwType.test(pw) && pw !== "" && idType.test(id) && id !== ""){
        if(pw !== "" && id !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [id,pw]);

    useEffect(() => {
        
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <InputContents
                    title="로그인"
                    subTitle={null}
                >
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="아이디"
                        placeholder="아이디를 입력하세요"
                        value={id}
                        max={20}
                        regexp={null}
                        func={(value)=>{setId(value)}}
                    />
                    <InputItemBox
                        inputType="password"
                        addClass=""
                        inputName="비밀번호"
                        placeholder="비밀번호를 입력하세요"
                        pwChType={true}
                        value={pw}
                        max={20}
                        regexp={null}
                        func={(value)=>{setPw(value)}}
                    />
                    <BtnItem
                        addClass="subType inputInBtn"
                        contents="로그인"
                        disabled={btnChk}
                        func={() => {loginStart()}}
                    />
                    <div className="loginSubLinkBox">
                        <LoginSubLink func={()=>{navigate("/member/find/id")}} contents="아이디 찾기"/>
                        <span></span>
                        <LoginSubLink func={()=>{navigate("/member/find/pw")}} contents="비밀번호 찾기"/>
                    </div>
                </InputContents>
            </ContentsSection>
            <Footer pcOnly={true}/>
        </>
    );
};

export default Login;