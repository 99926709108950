import useGet from "api/useGet";
import { useNavigate } from "react-router-dom";

//footer 메뉴
function Footer(data){
    const navigate = useNavigate();

    const footerApi = useGet({
        url:`/footer`,
        loginType:"login"
    });
    const footerApiData = footerApi?.data;

    return (
        <div className={`footer ${data.pcOnly ? "pcContents" : ""}`}>
            <div className="footerSection">
                <div className="footer_menuBox">
                    <button type="button" onClick={()=>{navigate("/terms/1")}} className="footer_menu">이용약관</button>
                    <span/>
                    <button type="button" onClick={()=>{navigate("/terms/2")}} className="footer_menu">개인정보처리방침</button>
                </div>
                <div className="footer_infoArea">
                    <div className="footer_infoBox">
                        {footerApiData?.company_name ? <p className="footer_info">{footerApiData?.company_name}</p> : ""}
                        {footerApiData?.company_address ? <p className="footer_info">{footerApiData?.company_address}</p> : ""}
                        {footerApiData?.ceo_name ? <p className="footer_info">대표이사 : {footerApiData?.ceo_name}</p> : ""}
                        {footerApiData?.company_registration_number ? <p className="footer_info">사업자등록번호 : {footerApiData?.company_registration_number}</p> : ""}
                        {footerApiData?.communication_number ? <p className="footer_info">통신판매업신고 : {footerApiData?.communication_number}</p> : ""}
                        {footerApiData?.email ? <p className="footer_info">이메일 : {footerApiData?.email}</p> : ""}
                        {footerApiData?.cs ? <p className="footer_info">고객센터 : {footerApiData?.cs}</p> : ""}
                    </div>
                    <div className="footer_iconBox">
                        <img src="/assets/images/img/footer_logo_0.svg"/>
                        <img src="/assets/images/img/footer_logo_1.svg"/>
                    </div>
                </div>
                <p className="footer_caption">Copyright 2022 eatmapl All rights reserved</p>
            </div>
        </div>
    );
}

export {Footer}