import { useEffect, useState } from "react";

export default function useGet(fatchData){
    const [data,setData] = useState({});

    const headersData = fatchData.loginType == "login" ? {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Accept' : 'application/json'
        },
    } : fatchData.loginType == "sessionLogin" ? {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
            'Accept' : 'application/json'
        },
    } : {
        method: "GET",
        headers: {
            'Accept' : 'application/json'
        },
    }

    useEffect(() => {
        if((fatchData.chk || fatchData.chk == undefined || fatchData.chk == null) && fatchData.url){
            fetch(`${process.env.REACT_APP_API_URL}${fatchData.url}`, headersData).then(function(response) {
                if(process.env.REACT_APP_TYPE == "live"){
                    if(response?.status !== "200"){
                        window.location.href = `/pageErr/${response?.status}`;
                    }
                }
                return response.json();
            }).then(function(fetchRd) {
                if (fetchRd.success == true) {
                    setData(fetchRd);
                }else{
                    setData(fetchRd);
                }
            });
        }
    },[fatchData.url]);

    return data;
}