import { useNavigate } from "react-router-dom";

function WelComeBtn(data){
    let navigate = useNavigate();

    return (
        <button type="button" id={data.id||""} className={`welComeBtn ${data.addClass ? data.addClass : ""} ${data.img ? data.img : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            {data.img ? <img src={`/assets/images/sns/${data.img}.svg`}/> : ""}
            {data.text ? data.text : ""}
        </button>
    );
}

function LoginSubLink(data){
    let navigate = useNavigate();

    return (
        <button type="button" className="loginSubLink" onClick={() => data.func()} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

export {WelComeBtn,LoginSubLink};