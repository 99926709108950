import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { CustomPopup, Popup } from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { DotText, PopupInputBox } from "component/basic";
import { CustomSelect, InputBoxCol2, InputItemBox } from "component/basic/formItems";
import { MypageArea, MypageAreaTitleBox, MypageBtnBox, MypageContents, MypageSection, MypageSideMenu } from "component/mypage/items";
import { OrderInputBox } from "component/product/order";
import { useDaumPostcodePopup } from "react-daum-postcode";

const EditProfile = (props) => {
    const navigate = useNavigate();

    const open = useDaumPostcodePopup();

    const [submitChk,setSubmitChk] =  useState(true);

    const [btnChk,setBtnChk] =  useState(false);
    const [popupData,setPopupData] =  useState(null);

    const [pwErr,setPwErr] =  useState(false);
    const [pwComErr,setPwComErr] =  useState(false);

    const profileDataApi = useGet({
        url:`/profile`,
        loginType:"login"
    });
    const user = profileDataApi?.data?.user;
    const user_address = profileDataApi?.data?.user_address;

    const [userData,setUserData] =  useState({
            login_id:null,
            phone:null,
            name:null,
            gender:null,
            birth:null,
            height:null,
            weight:null,

            pw:null,
            pwCom:null,

            address_name:null,
            address_phone:null,
            address_postcode:null,
            address:null,
            address_detail:null,
            delivery_request:null,
    });

    const [withdrawalReason,setWithdrawalReason] =  useState(null);
    const [withdrawalBtnChk,setWithdrawalBtnChk] =  useState(false);
    
    const [customPopupWithdrawal,setCustomPopupWithdrawal] =  useState(null);

    function userData_dataSet(key,val){
		let setData = {...userData}
		setData[key] = val;
		setUserData(setData);
    }

    function withdrawalOpen(){
		setTimeout(() => {
			setCustomPopupWithdrawal({
				addClass:"withdrawalCustomPopup",
				title:"회원 탈퇴",
                subTitle:"정말 맛있저염 회원을 탈퇴하시겠습니까?<br/>탈퇴한 계정 정보는 복구되지 않습니다.",
				closeType:true,
				closeFunc:(e) => {
					setTimeout(() => {
						setCustomPopupWithdrawal(null);
						setWithdrawalReason(null);
					}, 200);
				}
			});
		}, 100);
    }

    function withdrawalFunc(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            formData.append("reason", withdrawalReason);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/withdrawal",
                success: (data) => {
                    setPopupData({
                        addClass:null,
                        title:"회원 탈퇴 완료",
                        text:"맛있저염을 이용해 주셔서 감사합니다.<br/>다음에 또 찾아주세요!",
                        closeType:false,
                        closeFunc:(e) => {setPopupData(null);window.logOut()},
                        btnFunc0:(e)=>{setPopupData(null);window.logOut()},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:null,
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });

                    setTimeout(() => {
                        window.logOut()
                    }, 10000);

                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    function editProfileFunc(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            formData.append("name", userData?.name);
            formData.append("gender", userData?.gender);
            formData.append("birth", userData?.birth);

            if(userData?.pw){
                formData.append("password", userData?.pw);
            }

            formData.append("height", userData?.height);
            formData.append("weight", userData?.weight);

            formData.append("address_name", userData?.address_name);
            formData.append("address_phone", userData?.address_phone?.replace(/\-/g,""));
            formData.append("address_postcode", userData?.address_postcode);
            formData.append("address", userData?.address);
            formData.append("address_detail", userData?.address_detail);
            formData.append("delivery_request", userData?.delivery_request);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/profile/modify",
                success: (data) => {
                    let oldData = {...userData};
                    oldData.pw = null;
                    oldData.pwCom = null;

                    setUserData(oldData);

                    window.errMsg("프로필 정보가 저장되었습니다.");

                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    const handleComplete = (addrItem) => {
		let oldData = {...userData};

        oldData.address = addrItem.address;
        oldData.address_postcode = addrItem.zonecode;

        setUserData(oldData);
    };

	const addrShOpen = () => {
        open({ onComplete: handleComplete });
    };

    useEffect(() => {
        let oldData = {...userData};
        if(user){
            oldData.login_id = user?.login_id ? user?.login_id : null
            oldData.phone = user?.phone ? user?.phone : null
            oldData.name = user?.name ? user?.name : null
            oldData.gender = user?.gender ? user?.gender : null
            oldData.birth = user?.birth ? user?.birth : null
            oldData.height = user?.height ? user?.height : null
            oldData.weight = user?.weight ? user?.weight : null
        }
        if(user_address){
            oldData.address_name = user_address?.name ? user_address?.name : null;
            oldData.address_phone = user_address?.phone ? user_address?.phone : null;
            oldData.address_postcode = user_address?.address_postcode ? user_address?.address_postcode : null;
            oldData.address = user_address?.address ? user_address?.address : null;
            oldData.address_detail = user_address?.address_detail ? user_address?.address_detail : null;
            oldData.delivery_request = user_address?.delivery_request ? user_address?.delivery_request : null;
        }
        setUserData(oldData);
    }, [profileDataApi]);

    useEffect(() => {
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        if(userData?.pw || userData?.pwCom){
            if(userData?.pw !== userData?.pwCom && userData?.pw !== "" && userData?.pwCom !== ""){
                setPwComErr("비밀번호가 일치하지 않습니다.")
            }else{
                setPwComErr(null)
            }
    
            if(!pwType.test(userData?.pw) && userData?.pw !== ""){
                setPwErr("영문, 숫자, 특수문자 조합 8-20자로 설정해 주세요.")
            }else{
                setPwErr(null)
            }
        }

        if(userData?.gender && userData?.birth
            && (userData?.pw || userData?.pwCom ? pwType.test(userData?.pw) && userData?.pw !== "" && userData?.pw == userData?.pwCom : true)
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [userData]);

    useEffect(() => {
        if(withdrawalReason){
            setWithdrawalBtnChk(false)
        }else{
            setWithdrawalBtnChk(true)
        }
    }, [withdrawalReason]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                mBtn={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MypageContents>
                    <MypageSideMenu tapActive={4}/>
                    <MypageSection>
                        <MypageArea>
                            <MypageAreaTitleBox addClass="" title="프로필 수정"/>
                            <OrderInputBox>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="아이디"
                                        placeholder="아이디"
                                        value={userData?.login_id}
                                        max={null}
                                        regexp={null}
                                        readOnly={true}
                                        func={(value)=>{}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="휴대폰 번호"
                                        placeholder="휴대폰 번호"
                                        value={userData?.phone}
                                        max={13}
                                        phoneSetting={true}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        readOnly={true}
                                        func={(value)=>{}}
                                        btnName="변경"
                                        clickEv={()=>{navigate("/mypage/editPhone")}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType="password"
                                        addClass=""
                                        inputName="새 비밀번호"
                                        placeholder="영문, 숫자, 특수문자 조합 8~20자"
                                        value={userData?.pw}
                                        pwChType={true}
                                        max={20}
                                        regexp={null}
                                        errMsg={pwErr}
                                        func={(value)=>{userData_dataSet("pw",value)}}
                                    />
                                    <InputItemBox
                                        inputType="password"
                                        addClass=""
                                        inputName="새 비밀번호 확인"
                                        placeholder="영문, 숫자, 특수문자 조합 8~20자"
                                        value={userData?.pwCom}
                                        pwChType={true}
                                        max={20}
                                        regexp={null}
                                        errMsg={pwComErr}
                                        func={(value)=>{userData_dataSet("pwCom",value)}}
                                    />  
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="이름"
                                        placeholder="이름"
                                        value={userData?.name}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{userData_dataSet("name",value)}}
                                    />
                                    <CustomSelect
                                        addClass=""
                                        inputName="성별"
                                        placeholder="성별을 선택해주세요."
                                        value={userData?.gender}
                                        func={(name,val) => {userData_dataSet("gender",val)}}
                                        disabled={false}
                                        valKey="id"
                                        nameKey="name"
                                        options={[
                                            {id:"m",name:"남성"},
                                            {id:"f",name:"여성"}
                                        ]}
                                    />
                                </InputBoxCol2>

                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="출생년도"
                                        placeholder="출생년도"
                                        value={userData?.birth}
                                        max={4}
                                        unit={"년"}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        func={(value)=>{userData_dataSet("birth",value)}}
                                    />
                                    <InputBoxCol2>
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="키"
                                            placeholder="예 : 165"
                                            value={userData?.height}
                                            max={3}
                                            unit={"cm"}
                                            regexp={"number"}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            func={(value)=>{userData_dataSet("height",value)}}
                                        />
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="몸무게"
                                            placeholder="예 : 50"
                                            value={userData?.weight}
                                            max={3}
                                            unit={"kg"}
                                            regexp={"number"}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            func={(value)=>{userData_dataSet("weight",value)}}
                                        />
                                    </InputBoxCol2>
                                </InputBoxCol2>
                            </OrderInputBox>
                        </MypageArea>
                        <MypageArea>
                            <MypageAreaTitleBox addClass="" title="기본 배송지 정보"/>
                            <OrderInputBox>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="수령인 성함"
                                        placeholder="수령받으실 분의 이름"
                                        value={userData?.address_name}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{userData_dataSet("address_name",value)}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="연락처"
                                        placeholder="수령받으실 분의 연락처"
                                        value={userData?.address_phone}
                                        max={13}
                                        phoneSetting={true}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        func={(value)=>{userData_dataSet("address_phone",value)}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass="textType shIconInput"
                                        inputName="주소"
                                        placeholder="주소 검색"
                                        value={userData?.address}
                                        max={null}
                                        regexp={null}
                                        readOnly={true}
                                        func={(value)=>{}}
                                        clickInput={(value)=>{addrShOpen()}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="상세 주소"
                                        placeholder="상세 주소 입력"
                                        value={userData?.address_detail}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{userData_dataSet("address_detail",value)}}
                                    />
                                </InputBoxCol2>
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="배송 요청사항 <span>(선택)</span>"
                                    placeholder="공동현관 비밀번호 등 배송 시 참고사항 작성"
                                    value={userData?.delivery_request}
                                    max={null}
                                    regexp={null}
                                    func={(value)=>{userData_dataSet("delivery_request",value)}}
                                />
                            </OrderInputBox>
                        </MypageArea>
                        <MypageBtnBox>
                            <BtnBox
                                boxType="fixed"
                                addClass={`pcBasicBtnBox col2 mRow2`}
                            >
                                <BtnItem
                                    addClass=""
                                    contents={"프로필 정보 저장"}
                                    disabled={btnChk}
                                    func={() => {editProfileFunc()}}
                                />
                                <BtnItem
                                    addClass="line"
                                    contents={"회원 탈퇴"}
                                    disabled={false}
                                    func={() => {withdrawalOpen()}}
                                />
                            </BtnBox>
                        </MypageBtnBox>
                    </MypageSection>
                </MypageContents>
            </ContentsSection>
            <Footer pcOnly={true}/>
            <CustomPopup data={customPopupWithdrawal}>
                <PopupInputBox>
					<CustomSelect
                        addClass=""
                        inputName="회원 탈퇴 사유"
                        placeholder="사유를 선택해주세요."
                        value={withdrawalReason}
                        func={(name,val) => {setWithdrawalReason(val)}}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={[
                            {id:"더 이상 이용하지 않음",name:"더 이상 이용하지 않음"},
                            {id:"탈퇴 후 재가입",name:"탈퇴 후 재가입"},
                            {id:"상품 배송 불만",name:"상품 배송 불만"},
                            {id:"상품 교환/환불/반품 불만",name:"상품 교환/환불/반품 불만"},
                            {id:"상품 다양성/가격품질 불만",name:"상품 다양성/가격품질 불만"},
                            {id:"사이트 사용 빈도 낮음",name:"사이트 사용 빈도 낮음"},
                            {id:"사이트 이용 불편",name:"사이트 이용 불편"}
                        ]}
                    />
                    <div className="withdrawalInfoBox">
                        <DotText text="탈퇴 시, 계정 내 구독중인 상품이 있을 경우 탈퇴가 불가합니다."/>
                        <DotText text="구독 기간이 만료된 후 탈퇴하거나 고객센터에 문의해주세요."/>
                    </div>
                </PopupInputBox>
                <BtnBox
                    boxType="fixed"
                    addClass="row2"
                >
                    <BtnItem
                        addClass=""
                        contents="회원 탈퇴"
                        disabled={withdrawalBtnChk}
                        func={() => {
							withdrawalFunc();
                        }}
                    />
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        disabled={false}
                        func={() => {
                            setCustomPopupWithdrawal(null);
							setWithdrawalReason(null);
                        }}
                    />
                </BtnBox>
            </CustomPopup>
			<Popup
                data={popupData}
            />
        </>
    );
};

export default EditProfile;